<template>
  <div class="table-all-box">
    <div class="row table-top-row">
      <div class="tab row">
        <div class="tab-pane" :class="{ active: tabNumber == 1 }"  @click="tab(1)">
          Tasks
        </div>
        <div class="tab-pane" :class="{ active: tabNumber == 2 }" @click="tab(2)" v-if=" data.types != undefined && (data.types.includes('tenant') || data.types.includes('buyer') || data.types.includes('seller')) "  >
          Leases
        </div>
        <!-- <div class="tab-pane" :class="{ active: tabNumber == 3 }" @click="tab(3)">
          Sales Listings
        </div>
        <div class="tab-pane" :class="{ active: tabNumber == 4 }" @click="tab(4)" >
          Offers
        </div>
        <div class="tab-pane" :class="{ active: tabNumber == 5 }" @click="tab(5)" >
          Purchases Criteria
        </div>
        <div class="tab-pane" :class="{ active: tabNumber == 6 }" @click="tab(6)" >
          Matching
        </div> -->
      </div>
      <el-button class="btn" v-show="tabNumber === 1" @click="$refs.task.revisetask = true" >+ New Task</el-button >
      <el-button class="btn" v-show="tabNumber === 2" @click="createLease" >+ New Lease</el-button >
    </div>
    <el-divider></el-divider>
    <div v-show="tabNumber == 1">
      <Contact-Tasks ref="task"></Contact-Tasks>
    </div>
    <div v-show="tabNumber === 2" class="position-btn">
      <Lease-History :data="dataLaeses" :total="total" @pagesChanged="pagesChanged"></Lease-History>
    </div>
    <div v-show="tabNumber === 3" style="height: 200px"></div>
    <div v-show="tabNumber === 4" style="height: 200px"></div>
    <div v-show="tabNumber === 5" style="height: 200px"></div>
    <div v-show="tabNumber === 6" style="height: 200px"></div>
  </div>
</template>
<script>
import ContactTasks from "./ContactTasks";
import LeaseHistory from "../../common/LeaseHistory";
import { apiGetLeases } from "../../../API/api";
export default {
  components: { ContactTasks, LeaseHistory },
  props: ["data"],
  data() {
    return {
      tabNumber: 1,
      total: 1, // 总页数
      currentPage: 1, // 当前页数
      limit: 5, // 每页显示数量
      dataLaeses: [],
    };
  },
  computed: {
    offset() {
      return (this.currentPage - 1) * this.limit;
    },
    query(){
      return {
        limit: this.limit,
        offset: this.offset,
        tenant: this.$route.params.id,
      }
    }
  },
  methods: {
    tab(number) {
      this.tabNumber = number;
    },
    createLease() {
      let name = this.data.first_name + " " + this.data.last_name;
      let obj = { tenantName: name, tenant: this.data.contact_id };
      this.$store.commit("setLeaseDetail", false);
      this.$store.commit("setLeaseAgeent", false);
      this.$store.commit("setLeaseSecurityDeposit", false);
      this.$store.commit("setLeaseParam", obj);
      this.$router.push({ name: "create-lease" });
    },
    pagesChanged(number) {
      this.currentPage = number;
      this.getGetLeases();
    },
    getGetLeases() {
      apiGetLeases(this.query).then((res) => {
        this.dataLaeses = res.results;
        this.total = res.count;
        console.log(res);
      });
    },
  },
  created() {
    this.getGetLeases();
  },
};
</script>
<style lang="scss" scoped>
@import "../../../css/selefLeases.scss";
.table-all-box {
  margin-bottom: 100px;
}
</style>