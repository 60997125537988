<template>
  <div class="ckedit ckedit-note">
    <vue-editor v-model="content" :placeholder="dataPlaceholder" :editor-toolbar="customToolbar" />
  </div>
</template>
<script>
import { VueEditor } from "vue2-editor";
export default {
  components: { VueEditor },
  props: ["data",'dataPlaceholder'],
  data() {
    return {
      content: "",
      customToolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline"],
        [
          {
            color: [
              "#FF0000",
              "#00FF00",
              "#0000FF",
              "#FFFF00",
              "#FF00FF",
              "#00FFFF",
              "#FFA500",
              "#800080",
              "#808080",
              "#000000",
            ],
          },
        ],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ 'align': [] }],
        ["blockquote", "code-block"],,
      ],
    };
  },
  watch: {
    data(newv) {
      this.content = this.data;
    },
  },
  methods: {},
  computed: {
    getColors() {
      return selectColors || [];
    },
  },
};
</script>
<style lang="scss" scoped>
.ckedit {
  /deep/ .ck.ck-editor {
    .ck.ck-reset.ck-dropdown__panel.ck-dropdown__panel_se.ck-dropdown__panel-visible {
      overflow: auto;
    }
    p,
    li {
      // margin: 0;
      line-height: 24px;
    }
  }
}
</style>
