<template>
  <div>
    <el-col :lg="5" class="sidebar" v-loading="loading">
      <div class="inner-sidebarTwo" v-if="this.$route.params.id">
        <img src="../../../assets/img/profile-circle.svg" alt="" />
        <div v-if="contactData">
          <h1>{{ `${contactData.first_name} ${contactData.last_name}` }}</h1>

          <div class="top-infor" v-if="contactData.cell_phone">
            <img
              src="../../../assets/img/call.svg"
              class="head-content"
              alt=""
            />
            {{ contactData.cell_phone }}
          </div>
          <div class="top-infor">
            <img src="../../../assets/img/sms.svg" class="head-content" />
            {{ contactData.email1 }}
          </div>
        </div>
      </div>

      <div class="inner-sidebar" v-else>
        <h1>New Contact</h1>
        <p>
          <img src="../../../assets/img/call.svg" class="head-content" alt="" />
          Phone Number
        </p>
        <p>
          <img src="../../../assets/img/sms.svg" class="head-content" alt="" />
          Email Address
        </p>
      </div>

      <el-form :model="data" ref="data" :rules="rules" class="contactForm">
        <el-form-item prop="types" class="contact-type-validation">
          <div class="contact-type">
            <div class="items">
              <b>Types: </b
              >{{
                data.types.length <= 0 ? "Not specified" : data.types.join(", ")
              }}
            </div>
            <el-select
              v-model="data.types"
              @visible-change="selectType"
              multiple
            >
              <el-option
                v-for="item in typesData"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <div class="assignment" ref="dropdown">
          <div class="heading">
            <div>Assignment</div>
            <div class="link" @click="showOwnerSelect()">Re-assign</div>
          </div>
          <p>Assigned to {{ ownerName() }}</p>
          <div class="owner-dropdown" v-if="selectVisible">
            <div v-for="(item, index) in owners" :key="index">
              <div
                @click="data.user = item.user_id"
                :class="`item ${item.user_id == data.user ? 'active' : ''}`"
              >
                {{ `${item.first_name} ${item.last_name}` }}
              </div>
            </div>
          </div>
        </div>
        <div class="input-field reason-block" v-if="this.$route.params.id && (!selectVisible && owner_id!=data.user)">
            <div class="name-field">
                <el-input
                  class="text-name"
                  type="text"
                  v-model="data.reason"
                  placeholder="Reason"
                ></el-input>
            </div>
        </div>
        <div
              class="del-btn"
              v-if="$store.state.userInfo.is_admin === true"
              @click="$refs.delInfor.show = true"
              >Delete</div
            >
        <div class="personal-info" id="app">
          <div class="text-block">
            <div>
              <img src="../../../assets/img/user.svg" alt="" />
            </div>
            <p>Personal Information</p>
            <div>
              <img
                src="../../../assets/img/edit-2.svg"
                alt=""
                class="cursor-pointer"
                @click="toggleEdit"
              />
            </div>
          </div>
          <div>
            <img
              class="icon-arrow"
              src="../../../assets/img/arrow-up.svg"
              alt=""
              v-if="display"
              @click="toggleBar()"
            />
            <img
              class="icon-arrow"
              src="../../../assets/img/arrow-down.svg"
              alt=""
              v-else
              @click="toggleBar()"
            />
          </div>
        </div>
        <div class="scroll-bar" v-if="display">
          <div class="input-field">
            <label for="">First Name</label>
            <div class="name-field">
              <img
                class="icons-control"
                src="../../../assets/img/user.svg"
                alt=""
              />
              <el-input
                class="text-name"
                type="text"
                v-model="data.first_name"
                ref="focusableInput"
                v-bind:disabled="!isEditable"
              ></el-input>
            </div>
          </div>
          <div class="input-field">
            <label for="">Last Name</label>

            <el-form-item prop="last_name">
              <div class="name-field">
                <img
                  class="icons-control"
                  src="../../../assets/img/user.svg"
                  alt=""
                />
                <el-input
                  class="text-name"
                  type="text"
                  v-model="data.last_name"
                  v-bind:disabled="!isEditable"
                  >Henry</el-input
                >
              </div>
            </el-form-item>
          </div>
          <div class="input-field">
            <label for="">Email Address</label>

            <el-form-item prop="email1">
              <div class="name-field">
                <img
                  class="icons-control"
                  src="../../../assets/img/sms.svg"
                  alt=""
                />
                <el-input
                  class="text-name"
                  type="text"
                  v-model="data.email1"
                  v-bind:disabled="!isEditable"
                ></el-input>
              </div>
            </el-form-item>
          </div>
          <div class="input-field">
            <label for="">Email Address-2</label>

            <el-form-item prop="email2">
              <div class="name-field">
                <img
                  class="icons-control"
                  src="../../../assets/img/sms.svg"
                  alt=""
                />
                <el-input
                  class="text-name"
                  type="text"
                  v-model="data.email2"
                  v-bind:disabled="!isEditable"
                ></el-input>
              </div>
            </el-form-item>
          </div>

          <div class="input-field">
            <label for="">Mobile Phone</label>

            <div class="name-field">
              <img
                class="icons-control"
                src="../../../assets/img/call.svg"
                alt=""
              />
              <el-input
                class="text-name"
                v-model="data.cell_phone"
                maxlength="22"
                v-bind:disabled="!isEditable"
                @input="(e) => (data.cell_phone = changePhone(e))"
              >
              </el-input>
            </div>
          </div>
          <div class="input-field">
            <label for="">Home Phone</label>

            <div class="name-field">
              <img
                class="icons-control"
                src="../../../assets/img/call.svg"
                alt=""
              />
              <el-input
                class="text-name"
                v-model="data.home_phone"
                maxlength="22"
                v-bind:disabled="!isEditable"
                @input="(e) => (data.home_phone = changePhone(e))"
              >
                ></el-input
              >
            </div>
          </div>
          <div class="input-field">
            <label for="">Work Phone</label>

            <div class="name-field">
              <img
                class="icons-control"
                src="../../../assets/img/call.svg"
                alt=""
              />
              <el-input
                class="text-name"
                type="text"
                v-model="data.work_phone"
                maxlength="22"
                v-bind:disabled="!isEditable"
                @input="(e) => (data.work_phone = changePhone(e))"
              >
              </el-input>
            </div>
          </div>
          <div class="input-field">
            <label for="">Address</label>

            <div class="name-field">
              <img
                class="icons-control"
                src="../../../assets/img/location.svg"
                alt=""
              />
              <el-input
                class="text-name"
                type="text"
                v-model="data.street1"
                v-bind:disabled="!isEditable"
              ></el-input>
            </div>
          </div>
          <div class="input-field">
            <label for="">Address-2</label>

            <div class="name-field">
              <img
                class="icons-control"
                src="../../../assets/img/location.svg"
                alt=""
              />
              <el-input
                class="text-name"
                type="text"
                v-model="data.street2"
                v-bind:disabled="!isEditable"
              ></el-input>
            </div>
          </div>
          <div class="input-field">
            <label for="">City</label>

            <div class="name-field">
              <img
                class="icons-control"
                src="../../../assets/img/location.svg"
                alt=""
              />
              <el-input
                class="text-name"
                type="text"
                v-model="data.city"
                v-bind:disabled="!isEditable"
                ></el-input
              >
            </div>
          </div>
          <div class="input-field">
            <label for="">State</label>

            <div class="name-field">
              <img
                class="icons-control"
                src="../../../assets/img/location.svg"
                alt=""
              />
              <el-input
                class="text-name"
                type="text"
                v-model="data.state"
                v-bind:disabled="!isEditable"
                ></el-input
              >
            </div>
          </div>
          <div class="input-field">
            <label for="">Zip / Postal Code</label>

            <div class="name-field">
              <img
                class="icons-control"
                src="../../../assets/img/location.svg"
                alt=""
              />
              <el-input
                class="text-name"
                type="text"
                v-model="data.zip_code"
                v-bind:disabled="!isEditable"
              ></el-input>
            </div>
          </div>
          <div class="input-field">
            <label for="">Birthday</label>

            <div class="name-field">
              <img
                class="icons-control"
                src="../../../assets/img/calendar-2.svg"
                alt=""
              />
              <el-input class="text-name" type="date" v-model="data.date_of_birth" 
                v-bind:disabled="!isEditable"></el-input>
            </div>
          </div>
          <div class="input-field">
            <label for="">Salesperson</label>
            <div class="name-field">
              <img
                class="icons-control"
                src="../../../assets/img/briefcase.svg"
                alt=""
              />
              <el-input
                class="text-name"
                type="text"
                v-bind:disabled="!isEditable"
              ></el-input>
            </div>
          </div>
          <div class="input-field">
            <label for="">Company</label>

            <div class="name-field">
              <img
                class="icons-control"
                src="../../../assets/img/buliding.svg"
                alt=""
              />
              <el-input
                class="text-name"
                type="text"
                v-model="data.company_name"
                v-bind:disabled="!isEditable"
              ></el-input>
            </div>
          </div>
        </div>

        <div class="btn" v-if="buttonDisplay">
          <el-button class="button-cancel" @click="cancel">Clear</el-button>
          <el-button class="button-save" @click="save('data')">Save</el-button>
        </div>
      </el-form>
    </el-col>
    <DelDialog @del="delContact" ref="delInfor" :title="`Are you sure to delete this contact?`"></DelDialog>
  </div>
</template>

<script>
import {
  apiCreateContact,
  apiGetContact,
  apiUpdateContact,
  apiAgent,
  apiDeleteContact
} from "../../../API/api";
import { Minixs } from "../../../js/mixins";
import DelDialog from "../../common/DelDialog";
export default {
  name: "EditContact",
  mixins: [Minixs],
  components:{
    DelDialog
  },
  data() {
    return {
      loading: false,
      secondaryName: true,
      show: false,
      isEditable: false,
      display: true,
      checked: true,
      title: "New Contact",
      typesData: [
        {
          value: "tenant",
          label: "Tenant",
        },
        {
          value: "buyer",
          label: "Buyer",
        },
        {
          value: "seller",
          label: "Seller",
        },
        {
          value: "homeowner",
          label: "Homeowner",
        },
      ],
      data: {
        // reason:"",
        types: [],
        first_name: "",
        last_name: "",
        cell_phone: "",
        home_phone: "",
        work_phone: "",
        country: "",
        email2: "",
        email1: "",
        street1: "",
        street2: "",
        city: "",
        state: "",
        zip_code: "",
        use_sec_name_on_lease: false,
        sec_first_name: "",
        sec_last_name: "",
        sec_cell_phone: "",
        sec_home_phone: "",
        sec_work_phone: "",
        sec_country: "",
        sec_email2: "",
        sec_email1: "",
        sec_street1: "",
        sec_street2: "",
        sec_city: "",
        sec_state: "",
        sec_zip_code: "",
        user: "",
        date_of_birth: null,
      },
      contactData: null,
      owners: [],
      buttonDisplay: false,
      rules: {
        types: [
          {
            required: true,
            message: "this information is required",
            trigger: ["blur", "change"],
          },
        ],
        last_name: [
          {
            required: true,
            message: "this information is required",
            trigger: ["blur", "change"],
          },
        ],
        sec_last_name: [
          {
            required: true,
            message: "this information is required",
            trigger: ["blur", "change"],
          },
        ],
        email1: [
          {
            required: true,
            message: "this information is required",
            trigger: ["blur", "change"],
          },
          {
            type: "email",
            message: "Please input correct email address",
            trigger: ["blur", "change"],
          },
        ],
        sec_email1: [
          {
            required: true,
            message: "this information is required",
            trigger: ["blur", "change"],
          },
          {
            type: "email",
            message: "Please input correct email address",
            trigger: ["blur", "change"],
          },
        ],
        email2: [
          {
            type: "email",
            message: "Please input correct email address",
            trigger: ["blur", "change"],
          },
        ],
      },
      owner_id: "",
      selectVisible: false,
    };
  },

  methods: {
    back() {
      if (this.$route.params.id) {
        this.$router.push("/contact/" + this.$route.params.id);
      } else {
        this.$router.go(-1);
      }
    },
    showSelect() {
      this.show = true;
    },
    toggleBar() {
      this.display = !this.display;
      this.isEditable = this.display ? true : false;
      this.buttonDisplay = this.display ? true : false;
    },
    // 获取租客的详细信息
    getContactDetail() {
      this.loading = true;
      apiGetContact(this.$route.params.id).then((res) => {
        this.data = res;
        this.contactData = Object.assign({}, this.data);
        this.owner_id = res.user;
        this.loading = false;
        if (res.sec_first_name || res.sec_last_name || res.sec_email1) {
          this.show = true;
        }
      });
    },
    getAgentList() {
      // 获取pm用户列表
      apiAgent().then((res) => {
        this.owners = res.results;
      });
    },
    save(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          if (this.$route.params.id) {
            // 修改
            apiUpdateContact(this.$route.params.id, this.data)
              .then((res) => {
                this.$router.push({
                  name: "show-contact",
                  params: { id: res.contact_id },
                });
                this.$message.success(
                  "Contact information updated successfully"
                );
                this.contactData = Object.assign({}, this.data);
                this.loading = false;
                this.buttonDisplay = false;
                this.isEditable = false;
              })
              .catch((err) => {
                this.loading = false;
                this.$message.error("Something went wrong");
              });
          } else {
            // 创建
            apiCreateContact(this.data)
              .then((res) => {
                // 跳转到修改页面，获取代理人列表
                this.data = res;
                this.contactData = Object.assign({}, this.data);
                this.getAgentList();
                this.$router.push({
                  name: "show-contact",
                  params: { id: res.contact_id },
                });
                this.$message.success("Contact created successfully");
                this.loading = false;
              })
              .catch((err) => {
                this.loading = false;
                this.$message.error(err.response.data.detail);
              });
          }
        } else {
          setTimeout(() => {
            let isError = document.getElementsByClassName("is-error");
            let input = isError[0].querySelector("input");
            if (input) {
              input.focus();
            } else {
              isError[0].querySelector("textarea").focus();
            }
          }, 100);
          // return false;
        }
      });
    },
    cancel() {
      this.data.types = [];
      this.data.cell_phone =
        this.data.home_phone =
        this.data.work_phone =
        this.data.sec_cell_phone =
        this.data.sec_home_phone =
        this.data.sec_work_phone =
        this.data.date_of_birth = 
          null;
      this.data.sec_country =
        this.data.country =
        this.data.email2 =
        this.data.email1 =
        this.data.street1 =
        this.data.street2 =
        this.data.city =
        this.data.state =
        this.data.zip_code =
        this.data.last_name =
        this.data.first_name =
        this.data.sec_email2 =
        this.data.sec_email1 =
        this.data.sec_street1 =
        this.data.sec_street2 =
        this.data.sce_city =
        this.data.sec_state =
        this.data.sec_zip_code =
        this.data.sec_last_name =
        this.data.sec_first_name =
          "";
    },
    showOwnerSelect() {
      this.selectVisible = !this.selectVisible;
      if (this.selectVisible) {
        this.buttonDisplay = true;
      }
    },
    ownerName() {
      let user = this.owners.find((item) => {
        return item.user_id == this.data.user;
      });

      if (user) {
        return `${user.first_name} ${user.last_name}`;
      }

      return "..";
    },
    onWindowClick(event) {
      const targetElement = event.target;
      const itemElement = this.$refs.dropdown;
      if (
        itemElement &&
        !itemElement.contains(targetElement) &&
        itemElement !== targetElement
      ) {
        this.selectVisible = false;
      }
    },
    selectType(e) {
      if (e == true) {
        this.buttonDisplay = true;
      }
    },
    toggleEdit() {
      this.isEditable = true;
      this.buttonDisplay = true;
    },
    delContact() {
      apiDeleteContact(this.$route.params.id)
        .then((res) => {
          this.$refs.delInfor.show = false;
          this.$router.push("/contacts");
        })
        .catch((err) => {
          this.$message.error(err.response.data.detail);
        });
    },
  },
  created() {
    this.getAgentList();
    if (this.$route.params.id) {
      this.title = "Edit Contact";
      this.getContactDetail();
    } else {
      this.title = "New Contact";
      this.isEditable = true;
      this.display = true;
      this.buttonDisplay = true;
    }
  },
  mounted() {
    window.addEventListener("click", this.onWindowClick);
  },
  beforeUnmount() {
    window.removeEventListener("click", this.onWindowClick);
  },
};
</script>

<style>
.sidebar {
  border-radius: 10px;
  background: #fff;
}
.sidebar .top-infor {
  padding: 5px;
  display: flex;
}
/* .generalinfo {
    width: 18rem;
  } */
.sidebar h1 {
  color: #092a35;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  padding: 10px 0px;
  line-height: 25px;
  margin: 0px;
}
.inner-sidebarTwo {
  text-align: left;
  display: flex;
  align-items: flex-start;
  gap: 20px;
  align-items: center;
  margin: 0px 0px 0px 11px;
}
.el-select__tags input {
  border-radius: 8px;
  background: var(--primary-main-shade, #e5f1f4);
}
.personal-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 24px 10px 30px;
}
.personal-info .text-block {
  color: #092a35;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.personal-info .text-block p {
  word-break: initial;
}
.input-field label {
  float: left;
  color: #43555a;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}
.name-field {
  border-bottom: 1px solid #d8e2e4;
  width: 100%;
  position: relative;
  display: inline-flex;
}
.name-field .text-name {
  border: none;
  /* border-bottom: 1.5px solid #d8e2e4; */
  opacity: 0.699999988079071;
  width: 64%;
}
.name-field input {
  width: 100%;
  border: none;
}
.btn {
  padding: 20px;
  text-align: right;
}
@media only screen and (min-width: 1200px) {
  .el-col-lg-5 {
    width: 7rem;
    margin-right: 0.5rem;
  }
  .el-col-lg-7 {
    width: 16rem;
  }
}
@media only screen and (max-width: 600px) {
  .personal-info {
    padding: 8px 0px 24px 12px;
  }
}
.head-content {
  padding-right: 6px;
}
.scroll-bar {
  overflow-y: scroll;
}
.scroll-bar::-webkit-scrollbar,
.owner-dropdown::-webkit-scrollbar {
  width: 5px;
  border: none;
}
.scroll-bar::-webkit-scrollbar-thumb,
.owner-dropdown::-webkit-scrollbar-thumb {
  background: #d8e2e4;
  border-radius: 10px;
  height: 5px;
}
.icons-control {
  width: 14px;
  height: 14px;
  align-self: center;
}
.icon-arrow {
  width: 20px;
  cursor: pointer;
}
.contact-type {
  margin: 30px 20px 0px 30px;
  position: relative;
  text-align: start;
  border-radius: 8px;
  background: #e5f1f4;
}

.contact-type .items {
  position: relative;
  padding: 15px 50px 15px 5px;
  line-height: 20px;
}

.contact-type .el-select {
  position: absolute;
  top: 5px;
  right: 10px;
}

.contact-type .el-select .el-select__tags {
  visibility: hidden;
  display: none;
}

.contact-type .el-select .el-input__inner {
  border: none !important;
  background: none !important;
  height: 40px !important;
  visibility: hidden;
}

.assignment {
  border-radius: 8px;
  border: 1px solid #d8e2e4;
  background: #fff;
  margin: 10px 25px;
  position: relative;
  padding: 10px;
}
.assignment .heading {
  display: flex;
  margin: 0px 10px;
  justify-content: space-between;
  flex-wrap: wrap;
  color: #092a35;
  font-family: "Manrope";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}
.assignment p {
  text-align: left;
  padding-left: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin: 10px 0px;
}
.assignment .link {
  color: #1c4a5e;
  font-family: "Manrope";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  text-decoration: underline;
  text-underline-offset: 4px;
  text-decoration-color: #1c4a5e;
  cursor: pointer;
}

.assignment .owner-dropdown {
  border-radius: 6px;
  background: #fff;
  position: absolute;
  width: 100%;
  z-index: 100;
  height: 150px;
  overflow-y: scroll;
  text-align: left;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.assignment .owner-dropdown .item {
  color: #4c737f;
  font-size: 13px;
  font-weight: 600;
  padding: 10px;
  cursor: pointer;
}
.assignment .owner-dropdown .item.active {
  border-radius: 5px;
  background: rgba(229, 241, 244, 0.6);
}
.primary-button-save {
  color: #fff;
  background-color: #1c4a5e;
  border-color: #1c4a5e;
}
.input-field {
  margin: 0px 20px 15px 60px;
}
.name-field .el-input.is-disabled .el-input__inner {
  background: none !important;
  cursor: default !important;
}
.contactForm {
  margin-bottom: 37px;
}

.cursor-pointer {
  cursor: pointer;
}

.sidebar .button-save {
  border-radius: 4px;
  background: #1c4a5e;
  padding: 12px 30px;
  color: white;
  font-size: 14px;
  font-weight: 700;
}

.sidebar .button-cancel {
  border-radius: 4px;
  background: none;
  padding: 12px 30px;
  border: 2px solid #f53d6b;
  color: #f53d6b;
  font-size: 14px;
  font-weight: 700;
}

.contact-type-validation .el-form-item__error {
  padding-top: 5px !important;
  left: 30px !important;
}


.del-btn {
  color: #f56c6c;
    font-size: 16px;
    font-family: "Roboto-Bold", sans-serif;
    cursor: pointer;
    text-align: end;
    margin-right: 20px;
}

.reason-block{
  margin-left: 30px;
}

.reason-block .name-field .text-name{
  width:100%;
}
</style>
