<template>
  <div>
    <el-row :gutter="20" v-if="!realEstateSearchesData.is_new">
      <el-col :xs="24" :sm="6" :md="6" :lg="3" class="content-title">
        <div class="show-content">
          <h4>{{ data.title }}</h4>
          <el-divider direction="vertical"></el-divider>
        </div>
        <div class="show-content edit-search" @click="openModal()">
          <img
            src="../../../assets/icon/icon-pen.svg"
            style="margin-left: 10px; cursor: pointer"
            alt="Edit"
          />
          <div>Edit Search</div>
        </div>
      </el-col>
      <el-col :xs="24" :sm="18" :md="18" :lg="20">
        <!-- <div class="show-content-text">
          <h4>Notifications:</h4>
          <div>
            {{ data.is_active ? `Email: ${data.email_frequency}` : "" }}
          </div>
        </div>-->
        <div class="show-content-text">
          <h4>Update Types:</h4>
          <div v-if="data.update_types.length > 0">
            {{ CaptilizeArrayElement(data.update_types, update_types) }}
          </div>
        </div> 
        <div class="filter-box">
          <h4>Filter Criteria:</h4>
          <div class="filter-content">
            <div v-if="data.price_min || data.price_max">
              <h4>Price:</h4>
              {{ data.price_min ? `$${currency(data.price_min)}` : "Any" }} -
              {{ data.price_max ? `$${currency(data.price_max)}` : "Any" }}
            </div>
            <div v-else>
              <h4>Price:</h4>
              Any
            </div>
            <div v-if="data.bedroom_min">
              <h4>Min bedrooms:</h4>
              {{ data.bedroom_min }}
            </div>
            <div v-if="data.living_area_min">
              <h4>Min Living Area:</h4>
              {{ data.living_area_min }}
            </div>
            <div v-if="data.lot_size_min">
              <h4>Min Lot Size:</h4>
              {{ data.lot_size_min }}
            </div>
            <div v-if="data.listing_types.length > 0">
              <h4>Listing Types:</h4>
              {{ CaptilizeArrayElement(data.listing_types, listing_types) }}
            </div>
            <div v-if="data.neighbourhoods.length > 0">
              <h4>Areas:</h4>
              {{ CaptilizeArrayElement(data.neighbourhoods, neighborhoods) }}
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-divider
      direction="horizontal"
      class="component-divider"
      v-if="!realEstateSearchesData.is_new"
    ></el-divider>
    <el-dialog
      :visible.sync="visibleDialog"
      title="Real Estate Searches"
      class="main-dialog"
      center
      :show-close="false"
    >
      <div class="heading" style="display: flex; align-items: center">
        <h4>Search Name</h4>
        <el-divider direction="vertical"></el-divider>
        <!-- <h5 v-if="!isEditing">{{ form.title }}</h5> -->
        <el-col :xs="12" :sm="12" :md="12" :lg="12">
          <el-input
            v-model="form.title"
            style=""
            class="search-input"
            placeholder="Give Your Search a Name"
          >
          </el-input>
        </el-col>
        <!-- <img
        v-if="!isEditing"
        src="../../../assets/icon/icon-pen.svg"
        style="margin-left: 10px; cursor: pointer;"
        alt="Edit"
        @click="toggleEdit"
      />
      <img
        v-else
        style="margin-left: 10px; cursor: pointer;"
        height="22"
        width="22"
        src="../../../assets/icon/icon-save.svg"
        alt="Save"
        @click="toggleEdit"
      /> -->
      </div>
      <el-form
        :model="form"
        ref="searchForm"
        class="my-form"
        :rules="rules"
      >
        <!-- <div class="form-group-element">
          <el-col :xs="8" :sm="8" :md="8" :lg="6" class="label-content">
            <h4>Notification</h4>
            <el-divider direction="vertical"></el-divider>
          </el-col>
          <el-col :xs="16" :sm="16" :md="16" :lg="18" class="content-block">
            <el-switch
              v-model="form.is_active"
              active-color="#4C737F"
              inactive-color="#D8E2E4"
              active-text="Email"
              class="switch-element"
              :width="32"
            >
            </el-switch>
          </el-col>
        </div>
        <div class="form-group-element">
          <el-form-item prop="email_frequency" class="w-100">
            <el-col :xs="8" :sm="8" :md="8" :lg="6" class="label-content">
              <h4>Frequency</h4>
              <el-divider direction="vertical"></el-divider>
            </el-col>
            <el-col
              :xs="16"
              :sm="16"
              :md="16"
              :lg="18"
              class="content-block radio-group"
            >
              <el-radio-group v-model="form.email_frequency">
                <el-radio
                  v-for="(item, index) in email_frequencies"
                  :key="index"
                  :label="item[0]"
                  >{{ item[1] }}</el-radio
                >
              </el-radio-group>
            </el-col>
          </el-form-item>
        </div> -->
        <div class="form-group-element">
          <el-col :xs="8" :sm="8" :md="8" :lg="6" class="label-content">
            <h4>Update Types</h4>
            <el-divider direction="vertical" class="ml-1"></el-divider>
          </el-col>
          <el-col :xs="16" :sm="16" :md="16" :lg="18">
            <div
              class="switch-container"
              v-for="(item, index) in update_types"
              :key="index"
            >
              <input
                type="checkbox"
                class="toggle-switch"
                :value="item[0]"
                :id="`custom-switch-${searchKey}-${index}`"
                v-model="form.update_types"
              />
              <label
                :for="`custom-switch-${searchKey}-${index}`"
                class="switch-label"
              ></label>
              <span class="label-text">{{ item[1] }}</span>
            </div>
          </el-col>
        </div>
        <div class="title">Filter Criteria</div>
        <el-row :gutter="20" class="main-form-row">
          <el-col :sm="12" :md="12" :lg="12" style="margin-top: 0">
            <p class="my-label">Price Range</p>
            <div class="combined-container">
              <el-input v-model="form.price_min" placeholder="Min Price" min="0"
              type="number">
                <template slot="prefix">
                  <img
                    src="../../../assets/icon/price.svg"
                    class="prefix_icon"
                    style="margin-right: 10px"
                  />
                </template>
              </el-input>
              <el-input v-model="form.price_max" placeholder="Max Price " min="0"
              type="number">
                <template slot="prefix">
                  <img
                    src="../../../assets/icon/price.svg"
                    class="prefix_icon"
                    style="margin-right: 10px"
                  />
                </template>
              </el-input>
            </div>
            <!-- <div class="setting-width">
              <el-popover
                placement="bottom"
                popper-class="popper"
                trigger="click"
                v-model="showPrice"
              >
                <div class="row slider-container">
                  <p class="form-item-title">$0</p>
                  <el-slider
                    class="price-slider"
                    :v-modal="priceValue"
                    range
                    :max="30000"
                    :step="5000"
                    @change="priceChange"
                  />
                  <p class="form-item-title">{{ form.price_max }}</p>
                </div>
                <p class="form-item-title">
                  Selected Range: $ {{ form.price_min ? form.price_min : 0 }} -
                  ${{ form.price_max ? form.price_max : 30000 }}
                </p>
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                  "
                >
                  <el-button
                    style="background: #1c4a5e; color: white"
                    @click="confirmPrice"
                    >Confirm</el-button
                  >
                </div>
                <div slot="reference" class="reference show-price">
                  <el-button
                    style="width: 100%; padding: 0px 0px 0px 10px; height: 40px"
                    v-if="!form.price_max && !form.price_max"
                  >
                    <p
                      class=""
                      style="
                        color: #c0c4cc;
                        display: flex;
                        justify-content: start;
                      "
                    >
                      <img
                        src="../../../assets/icon/price.svg"
                        style="margin-top: -3px; margin-right: 10px"
                      />
                      <span>Price Range</span>
                    </p>
                  </el-button>
                  <el-button
                    v-else
                    class="row btn-row"
                    style="
                      color: #c0c4cc;
                      width: 100%;
                      padding: 12px 5px;
                      justify-content: center;
                      margin-top: 10px !important;
                    "
                  >
                    <span style="color: #606266 !important"
                      >{{
                        form.price_min
                          ? form.price_min
                          : 0 | currencyPrice("$ ", 0)
                      }}&#x3000; - &#x3000;{{
                        form.price_max
                          ? form.price_max
                          : "" | currencyPrice("$ ", 0)
                      }}</span
                    >
                  </el-button>
                </div>
              </el-popover>
            </div> -->
          </el-col>
          <el-col :sm="12" :md="12" :lg="12">
            <p class="my-label">Minimum Bedrooms</p>
            <el-input
              v-model="form.bedroom_min"
              class="bed-input"
              placeholder="No min"
              min="0"
              type="number"
            >
              <template slot="prefix">
                <img
                  src="../../../assets/icon/bed.svg"
                  class="prefix_icon"
                  style="margin-right: 10px"
                />
              </template>
            </el-input>
          </el-col>
          <el-col :sm="12" :md="12" :lg="12">
            <p class="my-label">Min. Living Area (sf)</p>
            <el-input
              v-model="form.living_area_min"
              placeholder="Min Living Area (sf)"
              min="0"
              type="number"
            >
              <template slot="prefix">
                <img
                  src="../../../assets/icon/icon-door.svg"
                  class="prefix_icon"
                  style="margin-right: 10px"
                />
              </template>
            </el-input>
          </el-col>
          <el-col :sm="12" :md="12" :lg="12">
            <p class="my-label">Min. Lot Size (sf)</p>
            <el-input
              min="0"
              type="number"
              v-model="form.lot_size_min"
              placeholder="Min Lot Size (sf)"
            >
              <template slot="prefix">
                <img
                  src="../../../assets/icon/icon-home-trend-down.svg"
                  class="prefix_icon"
                  style="margin-right: 10px"
                />
              </template>
            </el-input>
          </el-col>
          <el-col :sm="12" :md="12" :lg="12">
            <el-form-item>
              <p class="my-label">Listing Type</p>
              <el-select
                v-model="form.listing_types"
                placeholder="All Types"
                multiple
              >
                <el-option
                  v-for="(item, index) in listing_types"
                  :key="index"
                  :label="item[1]"
                  :value="item[0]"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :sm="12" :md="12" :lg="12">
            <p class="my-label">Area</p>
            <el-select v-model="form.neighbourhoods" placeholder="Any" multiple>
              <!-- <template slot="prefix">
                <img
                  src="../../../assets/icon/location.svg"
                  class="prefix_icon"
                  style="margin-right: 10px"
                />
              </template> -->
              <el-option
                v-for="(item, index) in neighborhoods"
                :key="index"
                :label="item[1]"
                :value="item[0]"
              ></el-option>
            </el-select>
          </el-col>
          <!-- Add more el-col components here for additional fields -->
        </el-row>
        <el-row
          type="flex"
          justify="center"
          class="action-buttons main-form-row"
        >
          <el-button class="primary-button save-button" @click="submitForm"
            >Save / update search settings</el-button
          >
          <el-button
            v-if="realEstateSearchesData.id"
            class="alert-button"
            @click="deleteSearchItem"
            >Delete</el-button
          >
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  apiCreateRealEstateSearchCriteria,
  apiUpdateRealEstateSearchCriteria,
} from "../../../API/api";
import dayjs from "dayjs";

export default {
  data() {
    return {
      form: {
        title: "Real Estate Searches",
        street: "",
        price_min: 0,
        price_max: 0,
        bedroom_min: null,
        bedroom_max: null,
        living_area_min: null,
        living_area_max: null,
        lot_size_min: null,
        lot_size_max: null,
        update_types: [],
        neighbourhoods: [],
        zoning: [],
        is_active: true,
        cc_to_agent: false,
        email_frequency: "",
        search_date: null,
        contact: 0,
        area: null,
        listing_types: [],
      },
      data: null,
      response: null,
      isIndeterminate: false,
      showPrice: false,
      priceValue: [0, 30000],
      checkAll: false,
      neighborhoods: [],
      update_types: [
        ["A", "New Listing"],
        ["price_change", "Price Change"],
        ["U", "Under Agreement"],
        ["S", "Sold"],
        ["R", "Removed from Market"],
      ],
      listing_types: [
        ["single family", "Single Family"],
        ["land", "Land"],
        ["condo", "Condo"],
        ["commercial", "Commercial"],
        ["multi family", "Multi Family"],
        ["co-op", "Co-Op"],
      ],
      email_frequencies: [],
      isEditing: false,
      visibleDialog: false,
      zonings: [],
      rules: {
        search_date: [
          {
            required: false,
            message: "Search Date is required",
            trigger: ["blur", "change"],
          },
        ],
        email_frequency: [
          {
            required: false,
            message: "Frequency is required",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  props: {
    searchKey: {
      type: Number,
    },
    realEstateSearchesData: {
      type: Object,
      default: {},
    },
    savedSearchEnums: {
      type: Object,
      default: {},
    },
    deleteItem: {
      type: Function,
    },
  },
  methods: {
    formateDate(data) {
      const formattedDate = dayjs(data).format("YYYY-MM-DD");
      return formattedDate.substring(0, 10);
    },
    async submitForm() {
      const id = this.contactId;
      this.form.contact = id;
      this.$refs["searchForm"].validate(async (valid) => {
        if (valid) {
          if (this.form.price_min == "") {
            this.form.price_min = null;
          }
          if (this.form.price_max == "") {
            this.form.price_max = null;
          }
          if (this.form.bedroom_min == "") {
            this.form.bedroom_min = null;
          }
          if (this.form.living_area_min == "") {
            this.form.living_area_min = null;
          }
          if (this.form.living_area_min == "") {
            this.form.living_area_min = null;
          }
          if (this.form.lot_size_min == "") {
            this.form.lot_size_min = null;
          }
          if (this.realEstateSearchesData?.id) {
            apiUpdateRealEstateSearchCriteria(this.realEstateSearchesData.id, {
              ...this.form,
              // search_date: this.formateDate(this.form.search_date),
            })
              .then(() => {
                this.$message.success(
                  "Search data has been updated successfully."
                );
                this.visibleDialog = false;
                this.data = Object.assign({}, this.form);
              })
              .catch((error) => {
                this.$message.error(error);
              });
          } else {
            await apiCreateRealEstateSearchCriteria(id, this.form)
              .then((data) => {
                this.$message.success(
                  "Search data has been saved successfully."
                );
                this.realEstateSearchesData.is_new = false;
                this.visibleDialog = false;
                this.realEstateSearchesData.id = data.id;
                this.data = Object.assign({}, this.form);
              })
              .catch((error) => {
                this.$message.error(error);
              });
            // this.response = await apiGetRealEstateSearchCriteria(this.contactId);
          }
        }
      });
    },
    toggleEdit() {
      this.isEditing = !this.isEditing;
    },
    priceChange(val) {
      this.priceValue = val;
      this.form.price_min = this.priceValue[0];
      this.form.price_max = this.priceValue[1];
    },
    confirmPrice() {
      this.showPrice = false;
    },
    openModal() {
      this.visibleDialog = !this.visibleDialog;
      if (this.realEstateSearchesData.is_new) {
        this.deleteItem(this.realEstateSearchesData);
      }
    },
    deleteSearchItem() {
      this.visibleDialog = false;
      this.deleteItem(this.realEstateSearchesData);
    },
    CaptilizeArrayElement(value, filterArray) {
      let tempArr = value.map((item) => {
        let val = filterArray.find((arr) => {
          if (arr[0] == item) {
            return arr;
          }
        });
        if (val) {
          return val[1];
        }
        return item;
      });
      return tempArr.join(", ");
    },
    currency(price) {
      const item = Number(price);
      return (
        (item &&
          item.toLocaleString(undefined, { maximumFractionDigits: 2 })) ||
        "0"
      );
    },
  },
  created() {
    this.form.title = this.realEstateSearchesData.title;
    this.form.street = this.realEstateSearchesData?.street;
    this.form.price_min = this.realEstateSearchesData.price_min;
    this.form.price_max = this.realEstateSearchesData.price_max;
    this.form.bedroom_min = this.realEstateSearchesData.bedroom_min;
    this.form.bedroom_max = this.realEstateSearchesData.bedroom_max;
    this.form.living_area_min = this.realEstateSearchesData.living_area_min;
    this.form.living_area_max = this.realEstateSearchesData.living_area_max;
    this.form.lot_size_min = this.realEstateSearchesData.lot_size_min;
    this.form.lot_size_max = this.realEstateSearchesData.lot_size_max;
    this.form.update_types = [...this.realEstateSearchesData.update_types];
    this.form.listing_types = [...this.realEstateSearchesData.listing_types];
    this.form.neighbourhoods = [...this.realEstateSearchesData.neighbourhoods];
    this.form.zoning = [...this.realEstateSearchesData.zoning];
    this.form.is_active = this.realEstateSearchesData.is_active;
    this.form.cc_to_agent = this.realEstateSearchesData.cc_to_agent;
    this.form.email_frequency = this.realEstateSearchesData.email_frequency
      ? this.realEstateSearchesData.email_frequency
      : null;
    this.form.search_date = this.realEstateSearchesData.search_date
      ? new Date(this.realEstateSearchesData.search_date)
      : null;
    this.form.contact = this.realEstateSearchesData.contact;
    this.form.listing_type = this.realEstateSearchesData.listing_type;
    this.form.area = this.realEstateSearchesData.area;
    this.data = Object.assign({}, this.form);
    this.neighborhoods = this.savedSearchEnums.real_estate_neighborhoods;
    // this.update_types = this.savedSearchEnums.update_types;
    // this.listing_types = this.savedSearchEnums.listing_types;
    this.email_frequencies = this.savedSearchEnums.frequency;
    this.zonings = this.savedSearchEnums.zoning;
    this.contactId = this.$route.params?.id;
    if (this.realEstateSearchesData.is_new) {
      this.visibleDialog = true;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../../css/button.scss";

.main-container {
  p {
    font-size: 14px;
    font-weight: 500;
  }
  .heading {
    display: flex;
    h4 {
      font-size: 16px;
      color: #6e7e83;
      letter-spacing: 0.5px;
    }
    h5 {
      font-size: 14px;
    }
    .el-divider--vertical {
      background-color: #000000;
      padding: 8px 0;
      margin-left: 10px;
    }
    .search-input {
      height: 34px;
      width: 100%;
      margin-left: 20px;
    }
  }

  .combined-container {
    display: flex;
    .el-input:first-child {
      margin-right: 10px;
    }
  }
  .my-form {
    .el-form-item {
      margin-bottom: 0;
      .el-form-item__content {
        margin-left: 0 !important;
        line-height: inherit;
        position: relative;
        font-size: 14px;
      }
    }
    .my-label {
      text-align: start;
      line-height: 16px;
      margin-bottom: 2px;
    }
    .action-buttons {
      margin-top: 20px;
    }
    .alert-button {
      color: #f56c6c;
      font-size: 16px;
      font-family: "Roboto-Bold", sans-serif;
      margin-top: 10px;
      width: 50%;
      margin-left: 0px !important;
      &:hover {
        cursor: pointer;
      }
    }

    .prefix_icon {
      height: 100%;
      width: 19px;
      text-align: center;
      line-height: 40px;
      margin-left: 10px;
    }
  }
  .setting-width {
    /deep/ .row {
      flex-wrap: nowrap;
    }

    .select-state {
      margin-top: 10px;
      text-align: left;
    }
  }
}
.popper {
  .price-row {
    justify-content: space-between;
  }

  .slider-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &.row {
      margin: 0px;
    }

    .price-slider {
      width: 200px;
      .switches {
        margin-top: 18px;
        .el-switch {
          margin: 10px 5px;
        }
        .switch-container {
          display: flex;
          align-items: center;
          width: 4rem;
        }
      }
      padding: 0 20px;
    }
  }

  .form-item {
    width: calc(calc(100% - 10px) / 2);

    .form-item-title {
      line-height: 24px;
      margin: 0;
    }

    /deep/ .el-input {
      /*在firefox下移除input[number]的上下箭头*/
      .el-input__inner {
        -moz-appearance: textfield;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
          -webkit-appearance: none !important;
        }
      }

      .el-input__prefix {
        line-height: 40px;
      }
    }
  }
}

/deep/ .el-input--prefix .el-input__inner {
  padding-left: 40px !important;
}

.prefix_icon {
  height: 100%;
  width: 19px;
  text-align: center;
  line-height: 40px;
  margin-left: 10px;
}

.top_search {
  margin: 40px 0 30px 0;

  .properties-top-row {
    justify-content: space-between;
    align-items: flex-start;

    .properties-filter {
      width: 100%;

      .properties-filter-infor {
        align-items: flex-start;
        flex-wrap: nowrap;

        /deep/ .el-range-editor .el-range-input {
          text-align: left;
        }

        .setting-width {
          width: 30%;

          /deep/ .row {
            flex-wrap: nowrap;
          }

          .select-state {
            margin-top: 10px;
            text-align: left;
          }
        }
      }

      .distance-top-margin {
        margin-top: 10px;
      }

      .reference {
        width: 100%;
        // margin: 0 10px 0 0px;
        height: 40px;
        padding: 0;
        color: #606266;

        .btn-row {
          /* margin-top: -10px !important; */
          width: calc(100% - 20px);
          margin: 0;
          padding: 0 10px;
          display: flex;
          align-items: normal;
          /* justify-content: flex-start; */
          /* gap: 15px; */
        }

        &:hover {
          background-color: #fff;
          border-color: #dcdfe6;
          color: #606266;
        }
      }

      .show-price {
        background-color: #fff;
        line-height: 40px;
        font-size: 14px;
      }
    }
  }
}

.form-group-element {
  display: flex;

  .label-content {
    display: flex;
    align-items: center;
    height: fit-content;
    letter-spacing: 0.5px;
    min-width: 135px;
  }

  .content-block {
    display: flex;
    align-items: center;
  }

  h4 {
    color: #6e7e83;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin: 10px 0px;
  }

  .el-divider--vertical {
    background-color: #000000;
    padding: 8px 0;
    margin-left: 20px;
  }

  .el-switch {
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
    margin-left: 10px;
    margin-bottom: 10px;
  }
  .el-switch:last-child {
    margin-bottom: 0px;
  }

  .el-radio-group {
    display: flex;
    margin-left: 10px;
    flex-wrap: wrap;
  }

  .toggle-switch {
    display: none;
  }

  /* Styling for the custom switch box */
  .switch-container:first-child {
    margin-top: 10px;
  }
  .switch-container {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
    margin-bottom: 10px;
    margin-left: 10px;
  }

  .switch-label {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 20px;
    background-color: #d8e2e4;
    border-radius: 15px;
    transition: background-color 0.3s ease;
    cursor: pointer;
  }

  /* Styling for the toggle dot */
  .switch-label::after {
    content: "";
    position: absolute;
    top: 3px;
    left: 4px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    transition: left 0.3s ease;
  }

  .label-text {
    margin-left: 10px;
    color: #2c3e50;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
  .toggle-switch:checked + .switch-label {
    background-color: #4c737f;
  }
  .toggle-switch:checked + .switch-label::after {
    left: calc(100% - 16px);
  }

  .ml-1 {
    margin-left: 10px !important;
  }
}



.title {
  color: #6e7e83;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  margin-top: 20px;
  padding: 10px 0px;
  border-bottom: 2px solid #e3eaee;
  letter-spacing: 1px;
}

.main-form-row {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  .setting-width {
    width: 100%;
  }

  .save-button {
    padding: 12px !important;
    width: 50%;
  }

  .bed-input {
    width: 50%;
    justify-content: flex-start;
    display: flex;
  }
}

.el-select {
  display: flex;
  justify-content: flex-start;
}

.edit-search {
  margin-top: 20px;
  cursor: pointer;

  div {
    color: #092a35;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration: underline;
    margin-left: 10px;
  }
}
.show-content,
.show-content-text {
  display: flex;
  align-items: center;
  color: #092a35;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-align: left;

  h4 {
    margin: 0px 5px 0px 0px;
  }
}

.filter-box {
  color: #092a35;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  h4 {
    margin: 0px;
  }

  .filter-content {
    margin-left: 10px;

    div {
      display: flex;
      align-items: center;

      h4 {
        margin-right: 5px;
      }
    }
  }
}

.show-content-text {
  align-items: start;
}

.main-dialog .el-dialog {
  width: 50%;
}

.el-radio {
  margin-right: 10px !important;
}

.w-100 {
  width: 100%;
}
.radio-group {
  padding-top: 10px;
}

.filter-content {
  flex: 4;
  text-align: left;
}
@media only screen and (max-width: 768px) {
  .main-dialog .el-dialog {
    width: 100%;
  }

  .title,
  .main-form-row {
    width: 100%;

    .bed-input {
      width: 100%;
    }
  }

  .content-title {
    display: flex;
    align-items: center;

    .edit-search {
      margin-top: 0px;
    }
  }
  .el-radio-group {
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .main-dialog .el-dialog {
    width: 80%;
  }
  .el-radio-group {
    margin-bottom: 10px;
  }
}
</style>
