<template>
  <div class="lease-table">
    <div class="table">
      <el-table :data="data" @row-click="rowClick">
        <el-table-column label="LEASE #" prop="lease_id" width="80"></el-table-column>
        <el-table-column label="CONTRACT DATE" min-width="120">
          <template slot-scope="scope">
            <p>
              {{ scope.row.contract_date | dateFormat }}
            </p></template >
        </el-table-column>
        <el-table-column label="HOMEOWNER" prop="owner_last_name" min-width="110">
        </el-table-column>
        <el-table-column label="RENTAL ADDRESS" prop="listing_address" min-width="120"></el-table-column>
        <el-table-column label="ARRIVAL" min-width="120">
          <template slot-scope="scope"><p >{{ scope.row.arrival_date | dateFormat }}</p></template>
        </el-table-column>
        <el-table-column label="DEPARTURE" min-width="120">
          <template slot-scope="scope"><p>{{ scope.row.departure_date | dateFormat }}</p></template>
        </el-table-column>
        <el-table-column label="STATUS" width="160" style="display: flex;align-items: center;justify-content: center;text-align: left;" >
          <template slot-scope="scope">
            <span class="lb_color" v-if="scope.row.status == 'Paid in Full'" style="background-color: #73edb0"></span>
            <span class="lb_color" v-else-if="scope.row.status == 'Past Due'" style="background-color: #db272d"></span>
            <span class="lb_color" v-else style="background-color: #fbca25" ></span>
            <span>{{ scope.row.status }}</span>
          </template>
        </el-table-column>
        <el-table-column label="DUE DATE" min-width="120">
          <template slot-scope="scope">
            <p>{{ scope.row.next_due_date | dateFormat  }}</p></template>
        </el-table-column>
        <el-table-column width="130" label="SD RETURNED?">
          <template slot-scope="scope">
            <p v-if="scope.row.security_deposit_status === 'Paid'">
              Yes
            </p>
            <p v-else>No</p>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <Page :total="total" :pageSize="5" @number="pagesChanged"></Page>
  </div>
</template>

<script>
import Page from "./Paging";
export default {
  components: { Page },
  props:["data", "total"],
  methods: {
    pagesChanged(number) {
      this.$emit("pagesChanged",number)
    },
    rowClick(vsl){
      console.log(vsl.lease_id,"vv")
      this.$router.push({ name: "edit-lease", params: { id: vsl.lease_id } });
    },
    // 到租约的详情页
    leaseDetail(id) {
      this.$router.push({ name: "edit-lease", params: { id: id } });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../css/table.scss";
/* lease */
.lease-table {
  border-radius: 10px;
  background: white;
  font-size: 16px;

  .lb_color {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;
  }
  /deep/ .el-table__header {
    padding: 10px 0px;
  }
  /deep/ .el-button--text {
    color: #678993;
    font-size: 16px;
  }
}
</style>
