<template>
  <div ref="viewBox">
    <div class="w-table">
      <el-table
        ref="multipleTable"
        :data="data"
        tooltip-effect="dark"
        @selection-change="checked"
        @sort-change="sort"
        :default-sort="orderContent"
        style="width: 100%"
        v-el-table-infinite-scroll="load"
        :row-class-name="settingStyle"
      >

        <el-table-column
        
          v-for="(col, index) in header"
          :key="index"
          :prop="col.prop"
          :label="col.label"
          :width="col.width"
          :min-width="col.minWidth"
          :type="col.type"
          :sortable="col.sortable"
          :header-align="col.headerAlign"
          :column-key="index.toString()"
        >

          <template slot-scope="scope" >
            <span
              v-if="col.label == 'PRICE'"
              >	Max Price | $ {{ scope.row.max_price ? scope.row.max_price.toLocaleString() : '0' }}</span
            >
            <img
              v-if="col.label == 'IMAGE'"
              @click="propertyPhoto(scope.row.listing_id)"
              v-lazy="scope.row.url"
              style="height: 70px; width: 100%"
            />
            <span
              v-if="col.label == 'ADDRESS'"
              style="word-wrap: break-word; width: 100%; display: inline-block"
              @click="propertyLocation(scope.row.listing_id)"
              >{{ scope.row.address }}</span
            >
            <span
              v-if="col.label == 'AREA'"
              @click="propertyLocation(scope.row.listing_id)"
              >{{ scope.row.area_name }}</span
            >
            <span
              v-if="col.label == 'BRS'"
              >{{ scope.row.bedroom_number }}
              </span
            >
            <span
              v-if="col.label == 'BATHS'"
              @click="propertyBedroomsAndBathrooms(scope.row.listing_id)"
              >{{ scope.row.bathroom_number }}</span
            >
            <span
              v-if="col.label == 'CAPACITY'"
              >{{ scope.row.capacity }}</span
            >
            <span
              v-if="col.label == 'OWNER'"
              >{{ scope.row.owner_last_name }}</span
            >
            <span
              v-if="col.label == 'TURNOVER'"
              >{{ scope.row.turnover_day }}</span>
            <span
              v-if="col.label == 'LAST UPDATE'"
              >{{
                scope.row.calendar_updated_at
                  ? scope.row.calendar_updated_at
                  : null | dateFormat
              }}</span
            >
            <span
              v-if="col.label == 'ARRIVAL'"
              >{{ scope.row.arrival_date }}</span
            >
            <span
              v-if="col.label == 'LEASE'"
              @click="leaseEdit(scope.row.lease_id)"
              >{{ scope.row.lease_id }}</span
            >
            <span
              v-if="col.label == 'AGENT'"
              @click="leaseEdit(scope.row.lease_id)"
              >{{ scope.row.user_first_name }}</span
            >
            <span
              v-if="col.label == 'CONTRACT DATE'"
              @click="leaseEdit(scope.row.lease_id)"
              >{{ scope.row.contract_date }}</span
            >
            <span
              v-if="col.label == 'TENANT'"
              @click="leaseEdit(scope.row.lease_id)"
              >{{ scope.row.tenant_last_name }}</span
            >
            <span
              v-if="col.label == 'RENTAL ADDRESS'"
              @click="leaseEdit(scope.row.lease_id)"
              >{{ scope.row.listing_address }}</span
            >
            <span
              v-if="col.label == 'DEPARTURE'"
              @click="leaseEdit(scope.row.lease_id)"
              >{{ scope.row.departure_date }}</span
            >
            <span :class="`deposit-status ${scope.row.status}`"
              v-if="col.label == ''"
              >{{ scope.row.status }}</span
            >
          </template>



        </el-table-column>

        <!-- <p slot="append" style="text-align:center; line-height:50px;" v-loading="loading"><a href="javascript:;"  @click="load()" class="blue01">点击加载更多</a></p> -->
      </el-table>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
import elTableInfiniteScroll from "el-table-infinite-scroll";
export default {
  name: "RentalTable",
  directives: { "el-table-infinite-scroll": elTableInfiniteScroll },
  props: [
    "offset",
    "total",
    "searchInfor",
    "header",
    "option",
    "limit",
    "flag",
    "data"
  ],
  data() {
    return {
      orderContent: { prop: "", order: "" },
      countNumber: 0,
      loading: false,
      flags: false,
    };
  },
  methods: {
    settingStyle({ row }) {
      if (row.not_renting_year) {
        return "red";
      } else {
        if (row.priority === 1) {
          return "blue";
        } else if (row.priority === 2) {
          return "green";
        }
      }
    },
    getDate(date, day) {
      return dayjs(date).add(day, "day").format("YYYY-MM-DD");
    },
    load(){
      if(this.$refs.viewBox.scrollTop+ this.$refs.viewBox.offsetHeight+20 >= this.$refs.viewBox.scrollHeight){
          this.$emit("load");
       }
    },
    propertyLocation(index) {
      let routeData = this.$router.resolve({
        name: "property-location",
        params: { id: index },
      });
      window.open(routeData.href, "_blank");
    },
    propertyBedroomsAndBathrooms(index) {
      let routeData = this.$router.resolve({
        name: "property-bedroomsandbathrooms",
        params: { id: index },
      });
      window.open(routeData.href, "_blank");
    },
    leaseEdit(index) {
      let routeData = this.$router.resolve({
        name: "edit-lease",
        params: { id: index },
      });
      window.open(routeData.href, "_blank");
    },
    // 跳转到房子的图片页面
    propertyPhoto(index) {
      this.$store.commit("setPropertyInfor", true);
      this.$store.commit("setPropertyReuirement", false);
      this.$store.commit("setPropertyCalendar", false);
      this.$store.commit("setPropertyPhoto", false);
      let routeData = this.$router.resolve({
        name: "property-photo",
        params: { id: index },
      });
      window.open(routeData.href, "_blank");
    },
    checked(item) {
      this.$emit("checked", item);
    },
    sort(item) {
      this.$emit("sort", item);
    },
    renderHeader(createElement, { column }) {
      return createElement(
        "div",
        {
          class: ["thead-cell"],
          on: {
            mousedown: ($event) => {
              this.handleMouseDown($event, column);
            },
            mouseup: ($event) => {
              this.handleMouseUp($event, column);
            },
            mousemove: ($event) => {
              this.handleMouseMove($event, column);
            },
          },
        },
        [
          // 添加 <a> 用于显示表头 label
          createElement("a", column.label),
          // 添加一个空标签用于显示拖动动画
          createElement("span", {
            class: ["virtual"],
          }),
        ]
      );
    },
    toggleSelection(rows) {
      rows.forEach((row) => {
        this.$nextTick(() => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      });
    },
    init(newV) {
      if (this.$store.state.propertiesCheckeds.length != 0) {
        this.toggleSelection(this.$store.state.propertiesCheckeds);
      }
      this.data = newV;
    },
    // 节流
    throttle(fn, delay, atleast) {
      /**函数节流方法
           @param Function fn 延时调用函数
           @param Number dalay 延迟多长时间
           @param Number atleast 至少多长时间触发一次
           @return Function 延迟执行的方法*/
      let timer = null;
      let previous = null;
      return function () {
        var now = +new Date();
        if (!previous) previous = now;
        if (atleast && now - previous > atleast) {
          fn();
          // 重置上一次开始时间为本次结束时间
          previous = now;
          clearTimeout(timer);
        } else {
          clearTimeout(timer);
          timer = setTimeout(function () {
            fn();
            previous = null;
          }, delay);
        }
      };
    },
  },
  watch: {
    header(val) {
      this.tableHeader = val;
    },
    data(newV) {
      this.init(newV);
    },
  },
  created() {
  },
  computed: {
    tableMaxHeight() {
      return window.innerHeight + 100 + "px";
    },
  },
};
</script>
<style lang="scss">
@import "./RentalTable.scss";
@import "../../../css/button.scss";

.deposit-status {
  display: block;
  text-align: center;
  border-radius: 4px;
  padding: 5px;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}

.deposit-status.Current {
  background:#E9F6FF;
  color: #219FFF;
}
.deposit-status.Past  {
  background: #FEECF1;
  color: #F53D6B;
}
.deposit-status.Paid  {
  background: #E9FBF6;
  color: #21D3A0;
}
</style>
