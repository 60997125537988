<template>
  <div class="homeowner_lease" v-if="Information.types != undefined && Information.types.includes('homeowner')">
    <!-- <div class="homeowner-tab">Lease Payment Information</div> -->
    <div class="homeowner_lease_infor">
      <el-form :model="formData">
        <!-- 展示详情 -->
        <div class="lessor_infor">
          <div class="lessor_infor_row">
            <label>Payee's Name</label>
            <span v-if="$store.state.userInfo.is_admin === false">{{
              JSON.stringify(Information.lease_payment_info) == "{}"
                ? Information.first_name + Information.last_name
                : formData.payee_name
            }}</span>
            <el-input v-else v-model="formData.payee_name" />
          </div>
          <div class="lessor_infor_row">
            <label>Street Address</label>
            <span v-if="$store.state.userInfo.is_admin === false">{{
              JSON.stringify(Information.lease_payment_info) == "{}"
                ? Information.street1
                : formData.address1
            }}</span>
            <el-input v-else v-model="formData.address1" />
          </div>
          <div class="lessor_infor_row">
            <label>Street Address 2</label>
            <span v-if="$store.state.userInfo.is_admin === false">{{
              JSON.stringify(Information.lease_payment_info) == "{}"
                ? Information.street2
                : formData.address2
            }}</span>
            <el-input v-model="formData.address2" v-else />
          </div>
          <div class="lessor_infor_row">
            <label>City</label>
            <span v-if="$store.state.userInfo.is_admin === false">{{
              JSON.stringify(Information.lease_payment_info) == "{}"
                ? Information.city
                : formData.city
            }}</span>
            <el-input v-model="formData.city" v-else />
          </div>
          <div class="lessor_infor_row">
            <label>State</label>
            <span v-if="$store.state.userInfo.is_admin === false">{{
              JSON.stringify(Information.lease_payment_info) == "{}"
                ? Information.state
                : formData.state
            }}</span>
            <el-input v-model="formData.state" v-else />
          </div>
          <div class="lessor_infor_row">
            <label>Zip / Postal Code</label>
            <span v-if="$store.state.userInfo.is_admin === false">{{
              JSON.stringify(Information.lease_payment_info) == "{}"
                ? Information.zip_code
                : formData.zip_code
            }}</span>
            <el-input v-model="formData.zip_code" v-else />
          </div>
          <div class="lessor_infor_row">
            <label>Country</label>
            <span v-if="$store.state.userInfo.is_admin === false">{{
              JSON.stringify(Information.lease_payment_info) == "{}"
                ? Information.country
                : formData.country
            }}</span>
            <el-input v-model="formData.country" v-else />
          </div>
        </div>
      </el-form>
      <el-button
        v-if="$store.state.userInfo.is_admin === false"
        @click="dialogAddress = true"
        :disabled="!this.$route.params.id"
        >Submit New Address to Admin</el-button
      >
      <el-button v-else @click="updateContact">Save</el-button>
    </div>
    <!-- name of lessor弹出框 -->
    <!-- <el-dialog
      title="Name of Lessor"
      :visible.sync="dialogLessor"
      width="400px"
    >
      <div class="dialog_contant">
        <el-form v-model="lessorInfor">
          <el-form-item label="Name of Lessor">
            <el-input v-model="lessorInfor.lessor" />
          </el-form-item>
        </el-form>
        <el-button @click="submitLessor">Submit Change Request</el-button>
      </div>
    </el-dialog> -->
    <!-- name of payee弹出框 -->
    <!-- <el-dialog
      title="Change the Payee's Name"
      :visible.sync="dialogPayee"
      width="400px"
    >
      <div class="dialog_contant">
        <el-form v-model="payeeInfor">
          <el-form-item label="Payee's Name">
            <el-input v-model="payeeInfor.payee" />
          </el-form-item>
        </el-form>
        <el-button @click="submitPayee">Submit Change Request</el-button>
      </div>
    </el-dialog> -->
    <!-- Submit New Adress to Admin弹出框 -->
    <el-dialog
      title="Submit New Adress to Admin"
      :visible.sync="dialogAddress"
      width="400px"
    >
      <div class="dialog_contant">
        <el-form :model="addressInfor" ref="addressInfor" label-width="120px">
          <el-form-item label="To">
            <p class="address_to">Admin</p>
          </el-form-item>
          <el-form-item
            label="Payee's Name"
            prop="payee_name"
            :rules="[
              {
                required: true,
                message: 'this information is required',
                trigger: 'blur',
              },
            ]"
          >
            <el-input v-model="addressInfor.payee_name" />
          </el-form-item>
          <el-form-item label="Street Address">
            <el-input v-model="addressInfor.address1" />
          </el-form-item>
          <el-form-item label="Street Address 2">
            <el-input v-model="addressInfor.address2" />
          </el-form-item>
          <el-form-item label="City">
            <el-input v-model="addressInfor.city" />
          </el-form-item>
          <el-form-item label="State">
            <el-input v-model="addressInfor.state" />
          </el-form-item>
          <el-form-item label="Zip">
            <el-input v-model="addressInfor.zip_code" />
          </el-form-item>
          <el-form-item label="Country">
            <el-input v-model="addressInfor.country" />
          </el-form-item>
        </el-form>
        <el-button @click="submitAddress('addressInfor')"
          >Submit Change Request</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { apiUpdateHomeownerInfor, apiUpdateContact } from "../../../API/api";
import dayjs from "dayjs";
export default {
  props: ["Information","datas"],
  data() {
    return {
      switchStatusData: {},
      formData: {
        payee_name: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        country: "",
        zip_code: "",
      },
      dialogLessor: false,
      lessorInfor: {},
      dialogPayee: false,
      payeeInfor: {},
      dialogAddress: false,
      addressInfor: {},
      data: {},
    };
  },
  methods: {
    submitAdminAddress() {
      if (this.formData.selectaddress === "primary") {
        this.changeRequest();
      } else if (this.formData.selectaddress === "secondary") {
        this.changeRequest();
      }
    },
    selectAddress(value) {
      if (value === "other") {
        if (this.$store.state.userInfo.is_admin === false) {
          this.dialogAddress = true;
        }
      } else if (value === "secondary") {
        this.data = Object.assign(
          {},
          { homeowner: this.$route.params.id, send_payment_to: "secondary" }
        );
      } else {
        this.data = Object.assign(
          {},
          { homeowner: this.$route.params.id, send_payment_to: "primary" }
        );
      }
    },
    submitLessor() {
      this.data = Object.assign(
        {},
        {
          homeowner: this.$route.params.id,
          lessor: this.lessorInfor.lessor,
        }
      );
      this.changeRequest();
    },
    submitPayee() {
      this.data = Object.assign(
        {},
        {
          homeowner: this.$route.params.id,
          payee: this.payeeInfor.payee,
        }
      );
      this.changeRequest();
    },
    submitAddress(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.data = Object.assign({}, this.addressInfor, {
            homeowner: this.$route.params.id,
          });
          this.changeRequest();
        } else {
          return false;
        }
      });
    },
    changeRequest() {
      if (this.$route.params.id) {
        apiUpdateHomeownerInfor(this.data)
          .then((res) => {
            this.$message.success("Sent successfully");
            this.dialogLessor = this.dialogPayee = this.dialogAddress = false;
          })
          .catch((err) => {
            this.$message.error("fail in send");
            this.dialogLessor = this.dialogPayee = this.dialogAddress = false;
          });
      } else {
        this.dialogLessor = this.dialogPayee = this.dialogAddress = false;
        this.$message("Please create a contact first");
      }
    },
    updateContact() {
      apiUpdateContact(this.$route.params.id, {
        lease_payment_info: this.formData,
      })
        .then((res) => {
          this.$message.success("Information modified successfully");
        })
        .catch((err) => {
          this.$message.error(
            "Information modification failed, please resubmit. "+err.response.data.detail
          );
        });
    },
  },
  computed:{

  },
  watch: {
    Information(newV, oldV) {
      if ( JSON.stringify(newV.lease_payment_info) === "{}" || newV.lease_payment_info === null ) {
        this.formData.payee_name = newV.first_name + " " + newV.last_name;
        this.formData.address1 = newV.street1;
        this.formData.address2 = newV.street2;
        this.formData.city = newV.city;
        this.formData.state = newV.state;
        this.formData.zip_code = newV.zip_code;
        this.formData.country = newV.country;
      } else {
        this.formData = newV.lease_payment_info;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../css/dialog.scss";
.homeowner_lease {
  padding-top: 10px;
  font-size: 16px;
  margin-bottom: 20px;
  background-color: #fff;
  color: #000;
  font-family: "Roboto-Regular", sans-serif;
  .homeowner-tab {
    text-align: left;
    font-size: 16px;
    font-family: "Roboto-Bold", sans-serif;
    padding-left: 20px;
    margin-bottom: 20px;
  }

  .homeowner_lease_infor {
    padding: 0 40px 20px 20px;
    .el-button {
      font-size: 16px;
      background: #678993 0% 0% no-repeat padding-box;
      color: #fff;
    }
    /deep/ .el-button.is-disabled,
    /deep/ .el-button.is-disabled:focus,
    /deep/ .el-button.is-disabled:hover {
      color: #c0c4cc;
      cursor: not-allowed;
      background-image: none;
      background-color: #f5f7fa;
      border: 0.017857rem solid #dcdfe6;
    }
    .infor_row {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      display: -webkit-flex;
      flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
      margin-bottom: 20px;
      .el-button {
        margin-left: 100px;
      }
      .infor_name {
        text-align: left;
        width: 300px;
        p {
          margin: 0;
        }
      }
      /deep/ .el-form-item {
        margin-bottom: 0;
      }
      /deep/ .el-radio {
        display: block;
        text-align: left;
        .el-radio__input.is-checked .el-radio__inner {
          border: none;
          background-image: url("../../../assets/icon/ico-checkbox.svg");
          background-size: cover;
        }
        .el-radio__input.is-checked + .el-radio__label {
          color: #606266;
        }
        .el-radio__inner {
          width: 17px;
          height: 17px;
          border-radius: 2px;
          margin-bottom: 10px;
          &::after {
            display: none;
          }
          &:hover {
            border-color: #678993;
          }
        }
      }
    }
    .lessor_infor {
      text-align: left;
      .lessor_infor_row {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 10px;
        /deep/ .el-input {
          width: 200px;
        }
        label {
          text-align: left;
          display: inline-block;
          width: 220px;
          margin-right: 80px;
          color: #666;
          @media (max-width: 700px) {
            width: 120px;
          }
        }
      }
    }
  }
}
.dialog_contant {
  .address_to {
    text-align: left;
    margin: 0;
    color: #000;
  }
  /deep/ .el-form-item__label {
    &::after,
    &::before {
      display: none;
    }
  }
  /deep/ .el-form-item {
    margin-bottom: 15px;
    color: #000;
  }
  /deep/ .el-button {
    background-color: #678993;
    color: #fff;
    margin-top: 30px;
  }
}
</style>