<template>
  <div class="contact-box contact-detail-page">
    <p class="back-title" @click="back">
      <img src="../../assets/icon/icon-left-back.svg" alt="" /><span
        >Back to Contacts</span
      >
    </p>
    <!-- contact infor -->
    <el-row :gutter="12" class="main-section">
      <!-- sidebar  -->
      <el-col :xs="12" :lg="5" class="generalinfo">
        <EditContact :data="data" @toggle-edit="toggleEdit" />
      </el-col>

      <el-col :xs="12" :lg="19" class="generalinfo">
        <div style="background-color: #fff" class="divider">
          <div class="row contact-tab-top">
            <div class="contact-tab row">
              <div
                class="tab-pane"
                :class="{ active: tabNumber === 1 }"
                @click="() => handleSelectTab(1)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5Z"
                    stroke="#1C4A5E"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M17 9L13.87 11.5C12.84 12.32 11.15 12.32 10.12 11.5L7 9"
                    stroke="#1C4A5E"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div class="tab-text">Email</div>
              </div>
              <div
                class="tab-pane"
                :class="{ active: tabNumber === 2 }"
                @click="() => handleSelectTab(2)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M8 2V5"
                    stroke="#6E7E83"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16 2V5"
                    stroke="#6E7E83"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z"
                    stroke="#6E7E83"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8 11H16"
                    stroke="#6E7E83"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8 16H12"
                    stroke="#6E7E83"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div class="tab-text">Notes</div>
              </div>
              <div
                class="tab-pane"
                :class="{ active: tabNumber === 3 }"
                @click="() => handleSelectTab(3)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M9.02 2.84016L3.63 7.04016C2.73 7.74016 2 9.23016 2 10.3602V17.7702C2 20.0902 3.89 21.9902 6.21 21.9902H17.79C20.11 21.9902 22 20.0902 22 17.7802V10.5002C22 9.29016 21.19 7.74016 20.2 7.05016L14.02 2.72016C12.62 1.74016 10.37 1.79016 9.02 2.84016Z"
                    stroke="#6E7E83"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16.5 11.5L12.3 15.7L10.7 13.3L7.5 16.5"
                    stroke="#6E7E83"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.5 11.5H16.5V13.5"
                    stroke="#6E7E83"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div class="tab-text">Rentals</div>
              </div>

              <div
                class="tab-pane"
                :class="{ active: tabNumber === 4 }"
                @click="() => handleSelectTab(4)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M10.49 2.22957L5.5 4.10957C4.35 4.53957 3.41 5.89957 3.41 7.11957V14.5496C3.41 15.7296 4.19 17.2796 5.14 17.9896L9.44 21.1996C10.85 22.2596 13.17 22.2596 14.58 21.1996L18.88 17.9896C19.83 17.2796 20.61 15.7296 20.61 14.5496V7.11957C20.61 5.88957 19.67 4.52957 18.52 4.09957L13.53 2.22957C12.68 1.91957 11.32 1.91957 10.49 2.22957Z"
                    stroke="#5E7B87"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9.05 11.8697L10.66 13.4797L14.96 9.17969"
                    stroke="#5E7B87"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div class="tab-text">Saved Searches</div>
              </div>
            </div>
           
          </div>
        </div>

        <div v-if="tabNumber === 1">
          <ContactEmail
            :contactID="$route.params.id"
            :contact="data"
          ></ContactEmail>

          <!-- <Homeowner-Add-Property
            v-if="data.types != undefined && data.types.includes('homeowner')"
          ></Homeowner-Add-Property> -->

          <!-- <div class="contact-email">
          <Homeowner-LeaseInfor
            :Information="data"
            :datas="data"
          ></Homeowner-LeaseInfor>
            <Contact-Communication-Log
              :contactID="$route.params.id"
              :contact="data"
            ></Contact-Communication-Log>
          </div> -->
        </div>
        <div v-if="tabNumber === 2">
          <Note></Note>
        </div>
        <div v-if="tabNumber === 3">
          <!-- <Homeowner-LeaseInfor
            :Information="data"
            :datas="data"
          ></Homeowner-LeaseInfor> -->
          <div class="table-all">
            <Rentaltab :data="data"></Rentaltab>
          </div>
        </div>
        <div v-if="tabNumber === 4" class="save-searches">
          <!-- <div class="main-container">
        <h2>Rental Searches</h2>
        <div v-for="(item, index) in rental_searches" :key="index">
          <Rental-Searches
            :rentalSearchesData="item"
            :saved-search-enums="savedSearchEnums"
            :delete-item="() => deleteRentalSearchItem(item)"
          ></Rental-Searches>
        </div>
        <div class="save-button-container">
          <el-button
            class="primary-button submit-button"
            @click="handleCreateRentalSearches"
          >
            + Create another Saved Search</el-button
          >
        </div>
        <el-divider></el-divider>
      </div> -->
          <div class="main-container">
            <h2>Real Estate Saved Searches</h2>
            <div v-for="(item, index) in real_estate_searches" :key="index">
              <Real-Estate-Searches
                :searchKey="index"
                :realEstateSearchesData="item"
                :saved-search-enums="savedSearchEnums"
                :delete-item="() => deleteRealEstateSearchItem(item)"
              ></Real-Estate-Searches>
            </div>
            <div class="save-button-container">
              <el-button
                class="primary-button submit-button"
                @click="handleCreateRealEstateSearches"
              >
                + Create Another Saved Search</el-button
              >
            </div>
          </div>
          <el-divider></el-divider>
          <div class="main-container">
            <h2>Rental Saved Searches</h2>
            <div v-for="(item, index) in rental_searches" :key="index">
              <Rental-Searches
                :searchKey="index"
                :rentalSearchesData="item"
                :saved-search-enums="savedSearchEnums"
                :delete-item="() => deleteRentalSearchItem(item)"
              ></Rental-Searches>
            </div>
            <div class="save-button-container">
              <el-button
                class="primary-button submit-button"
                @click="handleCreateRentalSearches"
              >
                + Create Another Saved Search</el-button
              >
            </div>
          </div>
        </div>
      </el-col>

    </el-row>
  </div>
</template>
<script>
import HomeownerLeaseInfor from "./Homeowner/HomeownerLeaseInfor";
import HomeownerAddProperty from "./Homeowner/HomeownerAddProperty";
import BasicInfor from "./Contact/BasicInfor";
import ContactCommunicationLog from "./Common/ContactCommunicationLog";
import RealEstateSearches from "./Contact/RealEstateSearches.vue";
import RentalSearches from "./Contact/RentalSearches.vue";
import ContactTable from "./Contact/ContactFoot";
import DelDialog from "../common/DelDialog";
import Note from "./Contact/ContactNote";
import ContactDetail from "./Contact/ContactDetail";
import EditContact from "./Contact/EditContact";
import Rentaltab from "./Contact/RentalTab.vue";
import ContactEmail from "./Contact/ContactEmail.vue";
import EmailDetail from "./Contact/EmailDetail.vue";
import AddEmail from "./Contact/AddEmail.vue";
import {
  apiGetContact,
  apiDeleteContact,
  apiGetRentalListingSearchCriteria,
  apiGetRealEstateSearchCriteria,
  apiDeleteRentalListingSearchCriteria,
  apiDeleteRealEstateSearchCriteria,
  apiGetSavedSearchEnums,
} from "../../API/api";
export default {
  components: {
    BasicInfor,
    ContactCommunicationLog,
    ContactTable,
    HomeownerLeaseInfor,
    HomeownerAddProperty,
    DelDialog,
    Note,
    ContactDetail,
    EditContact,
    Rentaltab,
    ContactEmail,
    EmailDetail,
    AddEmail,
    RentalSearches,
    RealEstateSearches,
  },
  data() {
    return {
      tabNumber: 1,
      data: {},
      title: "Are you sure to delete this contact?",
      rental_searches: [],
      real_estate_searches: [],
      rentalSearchesInitial: {
        title: "Rental Searches",
        street: "",
        price_min: null,
        price_max: null,
        start_date: null,
        end_date: null,
        nights_count: null,
        capacity: null,
        bedroom_count: null,
        is_active: false,
        cc_to_agent: false,
        amenities: [],
        neighborhoods: [],
        contact: null,
        dateRange: null,
        // Add more properties here for additional fields
        
        email_frequency: "asap",
        neighbourhoods: [],
        is_new: true,
        turnover_day: "sunday"
      },
      realEstateSearchesInitial: {
        title: "Real Estate searches",
        street: "",
        price_min: null,
        price_max: null,
        bedroom_min: null,
        bedroom_max: null,
        living_area_min: null,
        living_area_max: null,
        lot_size_min: null,
        lot_size_max: null,
        update_types: [],
        listing_types: [],
        neighbourhoods: [],
        zoning: [],
        is_active: true,
        cc_to_agent: false,
        email_frequency: "asap",
        search_date: null,
        contact: 0,
        is_new: true,
      },
      savedSearchEnums: {},
      is_edit: false,
    };
  },
  methods: {
    // 删除contact
    delContact() {
      apiDeleteContact(this.$route.params.id)
        .then((res) => {
          this.$refs.delInfor.show = false;
          this.$router.push("/contacts");
        })
        .catch((err) => {
          this.$message.error(err.response.data.detail);
        });
    },
    handleSelectTab(number) {
      this.tabNumber = number;
      //Reset all modal dialog to not open.
      if (number == 4) {
        this.real_estate_searches = this.real_estate_searches.map(
          (searchItem) => {
            if (searchItem.is_new) {
              searchItem.is_new = false;
            }
            return searchItem;
          }
        );
      }
    },
    back() {
      this.$router.push({ name: "contacts" });
    },
    getContactDetail() {
      // 获取租客的详细信息
      apiGetContact(this.$route.params.id).then((res) => {
        this.data = res;
      });
    },
    handleCreateRentalSearches() {
      this.rental_searches.push({
        ...this.rentalSearchesInitial,
        title:
          this.rentalSearchesInitial.title +
          " " +
          (this.rental_searches.length + 1),
      });
    },
    deleteRentalSearchItem(delItem) {
      apiDeleteRentalListingSearchCriteria(delItem.id)
        .then((res) => {
          this.$message.success("Search data has been deleted successfully.");
          this.rental_searches = this.rental_searches.filter(
            (searchItem) => searchItem.id !== delItem.id
          );
        })
        .catch((err) => {
          this.$message.error(err.response.data.detail);
        });
    },
    handleCreateRealEstateSearches() {
      this.real_estate_searches.push({
        ...this.realEstateSearchesInitial,
        title:
          this.realEstateSearchesInitial.title +
          " " +
          (this.real_estate_searches.length + 1),
      });
    },
    deleteRealEstateSearchItem(delItem) {
      if (delItem?.id) {
        apiDeleteRealEstateSearchCriteria(delItem.id)
          .then((res) => {
            this.$message.success("Search data has been deleted successfully.");
            this.real_estate_searches = this.real_estate_searches.filter(
              (searchItem) => searchItem.id !== delItem.id
            );
          })
          .catch((err) => {
            this.$message.error(err.response.data.detail);
          });
      } else {
        this.real_estate_searches = this.real_estate_searches.filter(
          (searchItem) => searchItem.is_new == false
        );
      }
    },
    toggleEdit() {
      this.is_edit = !this.is_edit;
    },
  },
  async created() {
    if (this.$route?.params?.id) {
      this.getContactDetail();
      this.contactId = this.$route?.params?.id;
      const rentalSearchesResponse = await apiGetRentalListingSearchCriteria(
        this.contactId
      );
      const realEstateSearchesResponse = await apiGetRealEstateSearchCriteria(
        this.contactId
      );
      this.savedSearchEnums = await apiGetSavedSearchEnums();
      if (rentalSearchesResponse.results.length !== 0) {
        this.rental_searches = rentalSearchesResponse.results;
      }
      if (realEstateSearchesResponse.results.length !== 0) {
        this.real_estate_searches = realEstateSearchesResponse.results;
      }
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../css/button.scss";
.contact-box {
  .back-title {
    font-size: 16px;
    text-align: left;
    color: #678993;
    display: flex;
    align-items: center;
    .el-icon-back {
      margin-right: 10px;
      font-weight: 600;
    }
    &:hover {
      cursor: pointer;
    }
    img {
      margin-right: 15px;
    }
  }
  .divider {
    /deep/ .el-divider--horizontal {
      margin-bottom: 0;
    }
  }
  .contact-tab-top {
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px 0 20px;
    border-bottom: 1px solid #e3eaee;
    .contact-tab {
      font-family: "Roboto-Regular", sans-serif;
      .tab-pane {
        font-size: 16px;
        margin-right: 47px;
        display: flex;
        align-items: center;
        padding-bottom: 12px;
        &:hover {
          cursor: pointer;
        }
        span {
          margin-right: 8px;
          img {
            margin-top: 2px;
          }
        }
        .tab-text{
          margin-left:5px;
        }
      }
      .active {
        color: #1c4a5e;
        font-size: 16px;
        font-family: "Roboto-Bold", sans-serif;
        border-bottom: 2px solid #1c4a5e;
      }

      @media only screen and (max-width: 787px) {
        .tab-pane {
          margin-bottom: 10px;
        }
      }
    }
    .del-btn {
      color: #f56c6c;
      font-size: 16px;
      font-family: "Roboto-Bold", sans-serif;
      &:hover {
        cursor: pointer;
      }
    }
  }
  // .contact-email {
  //   margin-top: 20px;
  // }
  .table-all {
    background-color: #fff;
    padding: 10px 15px;
    min-height: 60vh;
  }
  .main-container {
    /* display: -webkit-box; */
    h2 {
      text-align: start;
      margin-top: 1rem;
      color: #092a35;
    }
  }
  .save-button-container {
    display: flex;
  }
}
@media only screen and (max-width: 767px) {
  .el-col-xs-12 {
    width: 100%;
    margin-top: 10px;
  }
  .main-section {
    display: block !important;
  }
  .contact-box .contact-tab-top .contact-tab .tab-pane {
    margin-right: 5px;
  }
  .contact-box .contact-tab-top .contact-tab .tab-pane svg{
    width:20px;
    height: 20px;
  }
  .contact-box .contact-tab-top .contact-tab .tab-pane  .tab-text{
    font-size:15px;
  }
}


@media only screen and (max-width: 460px) {
  .contact-box .contact-tab-top .contact-tab .tab-pane svg{
    width:18px;
    height: 18px;
  }
  .contact-box .contact-tab-top .contact-tab .tab-pane  .tab-text{
    font-size:13px;
  }
}

// tab view media query end

.main-section {
  display: flex;
}
.save-searches {
  background-color: white;
  padding: 10px 0px 0px 20px;
  min-height: 50vh;
}
</style>
