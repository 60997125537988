var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"mian-head"},[_c('div',[_c('div',{staticClass:"mail-container"},[_c('el-form',{ref:"sendEmailForm",attrs:{"model":_vm.sendEmailData}},[_c('div',{staticClass:"inner-data"},[_c('div',{staticClass:"title-input"},[_vm._v("To")]),_c('div',{staticClass:"form-input"},[_c('el-input',{attrs:{"type":"text","placeholder":"Contact Name <contact@domain.com>","readonly":""},model:{value:(_vm.sendEmailData.tos),callback:function ($$v) {_vm.$set(_vm.sendEmailData, "tos", $$v)},expression:"sendEmailData.tos"}})],1)]),_c('div',{staticClass:"inner-data"},[_c('div',{staticClass:"title-input"},[_vm._v("CC")]),_c('div',{staticClass:"form-input"},[_c('el-input',{attrs:{"placeholder":"Separate multiple email with a semicolon"},on:{"blur":function($event){return _vm.select()}},model:{value:(_vm.sendEmailData.ccs),callback:function ($$v) {_vm.$set(_vm.sendEmailData, "ccs", $$v)},expression:"sendEmailData.ccs"}})],1)]),_c('el-form-item',{attrs:{"prop":"subject","rules":[
            {
              required: true,
              message: 'This information is required',
              trigger: ['blur'],
            },
          ]}},[_c('div',{staticClass:"inner-data"},[_c('div',{staticClass:"title-input"},[_vm._v("Subject")]),_c('div',{staticClass:"form-input"},[_c('el-input',{ref:"subject",model:{value:(_vm.sendEmailData.subject),callback:function ($$v) {_vm.$set(_vm.sendEmailData, "subject", $$v)},expression:"sendEmailData.subject"}})],1)])]),_c('div',{staticClass:"note-block"},[_c('NoteEditor',{ref:"desc",attrs:{"dataPlaceholder":`Type your email here`}})],1),_c('div',{staticClass:"btn-group"},[_c('el-button',{staticClass:"primary-button",on:{"click":_vm.start}},[_vm._v("Send")]),_c('el-button',{staticClass:"cancel-button",on:{"click":function($event){return _vm.$emit('go-back')}}},[_vm._v("Cancel")])],1)],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }