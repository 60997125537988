<template>
  <div class="note" v-loading="loading">
    <el-row :gutter="12" class="note-mobile">
      <el-col :xs="12" :lg="12" v-if="noteData.length > 0">
        <h2 class="head-title">Notes</h2>
        <div class="card" v-for="(item,index) in noteData" :key="index">
          <div class="card-head">
            <div class="head-item">
              <img class="notemen" :src="item.user_avatar" alt="" />
              <div class="head-detail">
                <div>
                  <h1>{{item.posted_by}}</h1>
                  <div class="item">
                    <div class="item">
                      <img
                        class="head-icon"
                        src="../../../assets/img/calendar-2.svg"
                      />
                    <div>
                      {{ formatDate(item.date)}}
                    </div>
                  </div>
                  <div class="item">
                      <img
                        class="head-icon"
                        src="../../../assets/img/clock.svg"
                        alt=""
                      />
                    <div>
                      {{ formatTime(item.date) }}
                    </div>
                  </div>
                  </div>
                </div>
                <div>
                  <img @click="openDelNoteDialog(item.comment_uuid)" class="dellBtn" src="../../../assets/img/trash.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <p v-html="item.content">
            </p>
          </div>
        </div>
      </el-col>
      <el-col :xs="12" :lg="12">
        <h2 class="head-title">Create Note</h2>
        <Ckeditors ref="edit" :dataPlaceholder="`Type your note here`"></Ckeditors>
        <div class="primary-btns">
          <button type="button" class="note-cancel">Cancel</button>
          <button type="button" class="note-save" @click="createNote">Add Note</button>
        </div>
      </el-col>
    </el-row>
    <!-- <Note ref="note" @createNote="createNote" :total="total" @pagesChanged="pagesChanged" :noteData="noteData"><template slot="table_btn">
        <el-table-column width="50">
          <template slot-scope="scope" v-if="  scope.row.user === $store.state.userInfo.user_id||$store.state.userInfo.is_admin === true ">
            <i class="del-btn el-icon-delete" @click="openDelNoteDialog(scope.row.comment_uuid)"></i>
          </template>
        </el-table-column>
      </template>
      <template slot="note_title">
        <div class="note-title row ">
          <span class="title-text">Notes</span><el-button class="block-button" @click="$refs.note.show = true">+ Add Note</el-button>
        </div>
      </template>
    </Note> -->
    <Del-Note @del="delNote" :title="title" ref="delInfor"></Del-Note>
  </div>
</template>
<script>
import Note from "../../common/Note";
import DelNote from "../../common/DelDialog";
import Ckeditors from "./ContactNoteEditor.vue";
import dayjs from "dayjs";
import {
  apiGetContactComments,
  apiCreateContactComment,
  apiDelContactComment,
} from "../../../API/api";
export default {
  components: { Note, DelNote, Ckeditors },
  data() {
    return {
      checked: true,
      noteData: [],
      total: 1,
      current: 1,
      // 删除note
      title: "Are you sure to delete this note?",
      id: "",
      loading:false,
    };
  },
  computed: {
    offset() {
      return (this.current - 1) * 5;
    },
    query() {
      return {
        offset: this.offset,
        limit: 5,
      };
    },
  },
  methods: {
    // 删除评论
    openDelNoteDialog(id) {
      this.id = id;
      this.$refs.delInfor.show = true;
    },
    delNote() {
      this.loading = true;
      apiDelContactComment(this.$route.params.id, this.id).then((res) => {
        this.loading = false;
        this.noteData.forEach((item, index) => {
          if (this.id === item.comment_uuid) {
            this.noteData.splice(index, 1);
            this.$refs.delInfor.show = false;
          }
        });
      });
    },
    edit() {
      this.$router.push({
        name: "edit-contact",
        params: { id: this.$route.params.id },
      });
    },
    createNote() {
      let note = this.$refs.edit.content;
      if (note.trim() === "") {
        this.$message("Comment cannot be empty");
        return;
      }
      this.loading = true;
      apiCreateContactComment(this.$route.params.id, {
        content: note,
      }).then((res) => {
        this.$refs.edit.content = "";
        this.getContactComments();
        this.loading = false;
      });
    },
    getContactComments() {
      apiGetContactComments(this.$route.params.id, this.query).then((res) => {
        this.noteData = res.results;
        this.total = res.count;
      });
    },
    pagesChanged(number) {
      this.current = number;
      this.getContactComments();
    },
    formatDate(date){
      return dayjs(date).format('MMMM DD, YYYY');
    },
    formatTime(time){
      return dayjs(time).format("HH:mm:ss");
    }
  },
  created() {
    this.getContactComments();
  },
};
</script>
<style lang="scss" scoped>
.note {
  // margin-top: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 4px;
  .del-btn {
    color: #f56c6c;
  }
  .note-title {
    justify-content: space-between;
    .title-text {
      font-family: "Roboto-Bold", sans-serif;
      font-size: 20px;
    }
  }
}
.card {
  border-radius: 8px;
  background: var(--white, #fff);
  box-shadow: 0px 7px 36px 0px rgba(9, 42, 53, 0.05);
  padding-bottom: 20px;
  margin-bottom: 15px;
}
.card-head {
  padding: 15px 0px 0px 20px;
  text-align: initial;
}

.card-head .head-item{
  display:flex;
}
.card-head .notemen {
  width: 44px;
  height: 44px;
  border-radius: 100%;
}
.head-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0px 15px;
}
.head-detail h1 {
  text-align: initial;
  line-height: 4px;
}
.item{
  display: flex;
  align-items: center;
  margin-right: 5px;
  flex-wrap: wrap;
  margin-bottom: 5px;
}
.head-detail .head-icon {
  padding-right: 4px;
}
.card-body {
  color: var(--dark-dark-2, #43555a);
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  padding-left: 17px;
}
.dellBtn {
  display: initial;
  text-align: end;
  position: inherit;
  padding-left: 2.3rem;
  cursor: pointer;
}
@media only screen and (min-width: 1200px) {
  .el-col-lg-6 {
    width: 60%;
  }
}
@media only screen and (max-width: 767px) {
  .el-col-xs-12 {
    width: 100%;
    margin-bottom: 19px;
  }
  .card {
    height: auto;
    padding-bottom: 10px;
  }
  .dellBtn {
    padding-left: 0px;
    margin-left: 25px;
  }
  .note-mobile{
    display: flex;
    flex-direction: column-reverse;
  }
}
.primary-btns {
  justify-content: right;
  display: flex;
  padding-top: 22px;
  gap: 10px;
}
.note-save {
  color: white;
  padding: 16px 40px;
  border-radius: 4px;
  background: #1c4a5e;
  cursor: pointer;
}
.note-cancel {
  color: white;
  padding: 16px 40px;
  border-radius: 4px;
  border: 1.5px solid #f53d6b;
  color: #f53d6b;
  background: transparent;
  cursor: pointer;
}
.head-title {
  text-align: left;
  font-family: Manrope;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}
</style>
