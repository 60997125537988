<template>
  <div class="table-all">
    <div class="row btn-row" ref="viewBox">
      <!-- <p class="show-page">
            Show 1 - {{ flag&&offset + limit + countNumber > total + countNumber ? total + countNumber : (offset + limit + countNumber) | currencyPrice("", "", "0") }} of {{ flag?(total + countNumber):(total + countNumber) | currencyPrice("", "", "0") }} listings
          </p> -->
      <!-- <p class="show-page">{{ total  | currencyPrice("", "", "0") }} listings</p> :height="tableMaxHeight"-->
      <!-- <el-button class="primary-button" @click="showAllPage"
          >Show on One Page</el-button> -->
      <div class="main-btn">
        <button
          type="button"
          :class="`rental-btn ${tabNumber == 1 ? 'active' : ''}`"
          @click="changeTab(1)"
        >
          Lease History
        </button>
        <button
          type="button"
          :class="`rental-btn ${tabNumber == 2 ? 'active' : ''}`"
          v-if="data.types != undefined && data.types.includes('homeowner')"
          @click="changeTab(2)"
          
        >
          Owner’s Listings
        </button>
        <button
          type="button"
          :class="`rental-btn ${tabNumber == 3 ? 'active' : ''}`"
          v-if="data.types != undefined && data.types.includes('homeowner')"
          @click="changeTab(3)"
          
        >
          Payment Information
        </button>
      </div>
      <button
        v-if="tabNumber == 1"
        v-loading="loading"
        class="add-class"
        type="button"
        @click="createLease()"
      >
        <div><img src="../../../assets/img/add.svg" alt="" /></div>
        <div>Create Lease</div>
      </button>
      <el-button
        v-if="tabNumber == 2"
        v-loading="loading"
        class="add-class"
        @click="addProperty"
      >
        <div style="display: contents">
          <img src="../../../assets/img/add.svg" alt="" />
          <div>Create Listing</div>
        </div>
      </el-button>
    </div>
    <div v-if="tabNumber == 1" v-loading="loading">
      <div class="rental-table-desktop">
        <RentalTable
          :header="leaseHeader"
          @sort="leaseSort"
          :data="leaseData"
          @load="load('lease')"
        >
        </RentalTable>
      </div>
      <RentalTableMobile :header="leaseHeader" :data="leaseData"></RentalTableMobile>
    </div>
    <div v-if="tabNumber == 2" v-loading="loading" >
      <div class="rental-table-desktop">
      <RentalTable
        :header="ownerHeader"
        @sort="ownerSort"
        :data="ownerData"
        @load="load('owner')"
      >
      </RentalTable>
      </div>
      <RentalTableMobile :header="ownerHeader" :data="ownerData"></RentalTableMobile>
    </div>
    <div v-if="tabNumber == 3">
      
      <Homeowner-LeaseInfor
            :Information="data"
            :datas="data"
          ></Homeowner-LeaseInfor>
    </div>
  </div>
</template>

<script>
import RentalTable from "../Contact/RentalTable.vue";
import RentalTableMobile from "../Contact/RentalTableMobile.vue";
import HomeownerLeaseInfor from "../Homeowner/HomeownerLeaseInfor";
import { apiGetListings, apiGetLeases } from "../../../API/api";
export default {
  props: ["data"],
  components: {
    RentalTable,
    RentalTableMobile,
    HomeownerLeaseInfor
  },
  data() {
    return {
      ownerData: [],
      leaseData: [],
      ownerHeader: [
        {
          props: "max_price",
          label: "PRICE",
          width: "250px",
          sortable: "custom",
        },
        {
          label: "IMAGE",
          width: "120px",
        },
        {
          props: "address",
          label: "ADDRESS",
          width: "160px",
          sortable: "custom",
        },
        {
          props: "area_name",
          label: "AREA",
          minWidth: "120px",
          sortable: "custom",
        },
        {
          label: "BRS",
          minWidth: "70px",
          sortable: "custom",
        },
        {
          props: "bathroom_number",
          label: "BATHS",
          minWidth: "90px",
          sortable: "custom",
        },
        {
          props: "capacity",
          label: "CAPACITY",
          minWidth: "110px",
          sortable: "custom",
        },
        {
          props: "owner_first_name",
          label: "OWNER",
          minWidth: "120px",
          sortable: "custom",
        },
        {
          props: "turnover_day",
          label: "TURNOVER",
          minWidth: "120px",
          sortable: "custom",
        },
        {
          props: "calendar_updated_at",
          label: "LAST UPDATE",
          minWidth: "140px",
          sortable: "custom",
        },
      ],
      leaseHeader: [
        {
          prop: "lease_id",
          label: "LEASE",
          width: "120px",
          sortable: "custom",
        },
        {
          prop: "user_first_name",
          label: "AGENT",
          width: "120px",
        },
        {
          prop: "contract_date",
          label: "CONTRACT DATE",
          width: "160px",
          sortable: "custom",
        },
        {
          prop: "tenant_last_name",
          label: "TENANT",
          minWidth: "120px",
          sortable: "custom",
        },
        {
          prop: "owner_last_name",
          label: "OWNER",
          minWidth: "120px",
          sortable: "custom",
        },
        {
          prop: "listing_address",
          label: "RENTAL ADDRESS",
          minWidth: "160px",
          sortable: "custom",
        },
        {
          prop: "arrival_date",
          label: "ARRIVAL",
          minWidth: "120px",
          sortable: "custom",
        },
        {
          prop: "departure_date",
          label: "DEPARTURE",
          minWidth: "130px",
          sortable: "custom",
        },
        {
          label: "",
          width: "120px",
        },
      ],
      tabNumber: 1,
      lease: {
        total: 1, // 总页数
        currentPage: 1, // 当前页数
        limit: 50,
        ordering: "",
      },
      owner: {
        total: 1, // 总页数
        currentPage: 1, // 当前页数
        limit: 50,
        ordering: "",
      },
      loading: false,
    };
  },
  computed: {
    ownerOffset() {
      return (this.owner.currentPage - 1) * this.owner.limit;
    },
    ownerQuery() {
      return {
        limit: this.owner.limit,
        offset: this.ownerOffset,
        homeowner: this.$route.params.id,
        ordering: this.owner.ordering,
        show_price: true,
      };
    },
    leaseOffset() {
      return (this.lease.currentPage - 1) * this.lease.limit;
    },
    leaseQuery() {
      return {
        limit: this.lease.limit,
        offset: this.leaseOffset,
        tenant: this.$route.params.id,
        ordering: this.lease.ordering,
      };
    },
  },
  methods: {
    getHomeownerPropertyList(refresh) {
      this.loading = true;
      apiGetListings(this.ownerQuery).then((res) => {
        if (refresh) {
          this.ownerData = [...this.ownerData, ...res.results];
        } else {
          this.ownerData = res.results;
        }
        this.owner.total = res.count;
        this.loading = false;
      });
    },
    addProperty() {
      this.$router.push({ name: "create-property" });
      this.$refs.dialog.show = true;
    },
    getGetLeases(refresh) {
      this.loading = true;
      apiGetLeases(this.leaseQuery).then((res) => {
        if (refresh) {
          this.leaseData = [...this.leaseData, ...res.results];
        } else {
          this.leaseData = res.results;
        }
        this.lease.total = res.count;
        this.loading = false;
      });
    },
    changeTab(tab) {
      this.tabNumber = tab;
    },
    createLease() {
      let name = this.data.first_name + " " + this.data.last_name;
      let obj = { tenantName: name, tenant: this.data.contact_id };
      this.$store.commit("setLeaseDetail", false);
      this.$store.commit("setLeaseAgeent", false);
      this.$store.commit("setLeaseSecurityDeposit", false);
      this.$store.commit("setLeaseParam", obj);
      this.$router.push({ name: "create-lease" });
    },
    load(type) {
      if (type == "lease" && this.lease.total < this.lease.currentPage) {
        this.lease.currentPage += 1;
        this.getGetLeases(false);
      } else if (type == "owner" && this.owner.total < this.owner.currentPage) {
        this.owner.currentPage += 1;
        this.getHomeownerPropertyList(false);
      }
    },
    leaseSort(val) {
      if (val.order === "ascending") {
        this.lease.ordering = val.prop;
      } else if (val.order === "descending") {
        this.lease.ordering = "-" + val.prop;
      }
      this.currentPage = 1;
      this.getGetLeases(true);
    },
    ownerSort(val) {
      if (val.order === "ascending") {
        this.owner.ordering = val.prop;
      } else if (val.order === "descending") {
        this.owner.ordering = "-" + val.prop;
      }
      this.currentPage = 1;
      this.getHomeownerPropertyList(true);
    },
  },
  //设置给租客指定房屋
  created() {
    if (this.$route.params.id) {
      this.getHomeownerPropertyList(true);
      this.getGetLeases(true);
    }
  },
};
</script>

<style>
.custom-btn {
  background-color: black;
}
.rental-table-mobile{
  display:none;
}
@media screen and (max-width:767px) { 
  .rental-table-desktop{
    display: none;
  } 
  .rental-table-mobile{
    display:block;
  }
}
</style>
