<template>
  <div class="communit" >
    <!-- Send Email -->
    <div class="addtenant" v-loading="disable">
      <div class="addtenant_top">
        <span>Send Email</span>
      </div>
      <el-divider></el-divider>
      <div class="send_contant">
        <el-form ref="sendEmailForm" :model="sendEmailData" label-width="60px">
          <el-form-item label="To">
            <el-checkbox-group v-model="sendEmailData.tos">
              <el-checkbox-button label="1" >Primary Contact Email</el-checkbox-button >
              <el-checkbox-button label="2" :disabled="!contact.email2" >Primary Contact Email 2</el-checkbox-button >
              <el-checkbox-button label="3" :disabled="!contact.sec_email1" >Secondary Contact Email</el-checkbox-button >
              <el-checkbox-button label="4" :disabled="!contact.sec_email2" >Secondary Contact Email 2</el-checkbox-button >
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="CC" style="max-width: 700px; width: 100%">
            <el-input v-model="sendEmailData.ccs" placeholder="Separate multiple email with a semicolon" @blur="select"></el-input>
          </el-form-item>
          <el-form-item  label="Subject" prop="subject" :rules="[ { required: true, message: 'This information is required', trigger: ['blur'], }, ]" >
            <el-input v-model="sendEmailData.subject" ref="subject" v-focus="$route.hash == '#send-email'" ></el-input>
          </el-form-item>
          <el-form-item label="Body" prop="desc" :rules="[{ required: true, message: 'This information is required', trigger: ['blur'], },] " >
            <el-input type="textarea" resize="none" :autosize="{ minRows: 4 }" v-model="sendEmailData.desc" ></el-input>
          </el-form-item>
          <el-form-item>
            <div class="upload_file">
              <el-upload class="upload-demo" action="upload-demo" :http-request="fileChange" :show-file-list="false" >
                <p v-if="fileName === 'Add Attachment'" class="upload-img">
                  {{ fileName }}
                  <img src="../../../assets/img/file.png" alt />
                </p>
                <span v-else class="tip">{{ fileName }}</span>
              </el-upload>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class="btn">
        <el-button class="primary-button" @click="start">Send Email</el-button>
      </div>
    </div>
    <!--Communication Log  -->
    <div class="addtenant">
      <div class="addtenant_top communication-top">
        <span>Communication Log</span>
      </div>
      <div class="communication table">
        <el-table :data="datas" type="index" v-loading="loading">
          <el-table-column label="DATE" width="180">
            <template slot-scope="scope">{{ scope.row.created_at | dateFormat }}</template>
          </el-table-column>
          <el-table-column label="" min-width="300">
            <template slot-scope="scope">
              <p class="email-subiect">{{ scope.row.subject }}</p>
              <div v-if="scope.row.body != null">
                <div class="email-body" v-if="scope.row.body.length <= 500"> {{ scope.row.body }}</div>
                <div v-else-if="scope.row.body.length > 500">
                  <div v-show="!scope.row.show">
                    <p class="email-body">{{ scope.row.body | intercept }}... </p>
                    <p class="fr" @click="openShow(scope.$index)">READ MORE</p>
                  </div>
                  <div v-show="scope.row.show">
                    <p class="email-body">{{ scope.row.body }}</p>
                    <p class="fr" @click="closeShow(scope.$index)">PACK UP</p>
                  </div>
                </div>
              </div>
              <div v-else>
                <!-- <div v-for="(item, index) in scope.row.parts" :key="index"> -->
                <div v-if=" scope.row.parts[scope.row.parts.length - 1].mime_type === 'text/html' " >
                  <p v-show="!scope.row.show" v-html="scope.row.parts[scope.row.parts.length - 1].body" class="item" :style="showText ? 'height: 200px' : 'height: inherit'"
                    @click="toggle(scope.$index)" ></p>
                  <p v-show="scope.row.show" v-html="scope.row.parts[scope.row.parts.length - 1].body" style="height: inherit" @click="toggle(scope.$index)" ></p>
                  <p class="fr" v-show="!scope.row.show" @click="openShow(scope.$index)" > READ MORE </p>
                  <p class="fr" v-show="scope.row.show" @click="closeShow(scope.$index)" > PACK UP</p>
                </div>

                <div v-else>
                  <!-- <div v-if="index<=1"> -->
                  <div class="email-body" v-if=" scope.row.parts[scope.row.parts.length - 1].body.length <= 500 " >{{ scope.row.parts[scope.row.parts.length - 1].body }}
                  </div>
                  <div v-else-if=" scope.row.parts[scope.row.parts.length - 1].body.length > 500 " >
                    <div v-show="!scope.row.show">
                      <p class="email-body">{{ scope.row.parts[scope.row.parts.length - 1].body  | intercept }}...
                      </p>
                      <p class="fr" @click="openShow(scope.$index)">
                        READ MORE
                      </p>
                    </div>
                    <div v-show="scope.row.show">
                      <p class="email-body"> {{ scope.row.parts[scope.row.parts.length - 1].body }}
                      </p>
                      <p class="fr" @click="closeShow(scope.$index)">PACK UP</p>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column width="80">
            <template slot-scope="scope">
              <el-button class="reply" @click="reply(scope.row, false)" type="text" size="small" >Reply</el-button >
            </template>
          </el-table-column>
          <el-table-column width="90">
            <template slot-scope="scope">
              <el-button class="reply" @click="reply(scope.row, true)" type="text" size="small" >Reply All</el-button >
            </template>
          </el-table-column>
        </el-table>
        <Page :total="total" :pageSize="5" @number="pagesChanged"></Page>
      </div>
    </div>
    <!-- 查看邮件 -->
    <el-dialog :visible.sync="showClog">
      <div class="view">
        <p class="view_tip">Sender</p>
        <div class="view_content">{{ clogContent.sender }}</div>
        <p class="view_tip">To</p>
        <div class="view_content">{{ clogContent.tos | arrJoin("; ") }}</div>
        <p class="view_tip">CC</p>
        <div class="view_content">{{ clogContent.ccs | arrJoin("; ") }}</div>
        <p class="view_tip">Subject</p>
        <div class="view_content">{{ clogContent.subject }}</div>
        <p class="view_tip">Body</p>
        <div class="view_content view_body">{{ clogContent.body }}</div>
      </div>
      <span slot="footer" class="dialog-footer view_btn">
        <el-button class="delete" @click="showClog = false">Confirm</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="showLog" :title="title">
      <div class="reply-content">
        <p> To:
          <span v-for="(item, index) in content.tos" :key="index" style="display: inline-block; margin-right: 10px" > &lt;&lt;{{ item }}&gt;&gt;</span  >
        </p>
        <p v-show="sendAllTos">
          Cc: {{agent_email}};
          <span v-for="(item, index) in content.ccs" :key="index" style="display: inline-block; margin-right: 10px" >
            &lt;&lt;{{ item }}&gt;&gt;
          </span>
        </p>
        <el-input type="textarea" resize="none" :rows="4" v-model="body" />
      </div>
      <el-button class="primary-button reply-btn" :loading="loadingreply" @click="sumbit" >Send</el-button >
    </el-dialog>
  </div>
</template>

<script>
import { apiGetCommunicationLogs, apiEmailToContact, apiReplyToCommunicationLog } from "../../../API/api";
import Page from "../../common/Paging";
export default {
  name: "ContactCommunicationLog",
  components: { Page },
  data() {
    return {
      agent_email:"",
      loadingreply:false,
      sendAllTos: false,
      showText: false,
      // 回复邮件
      showLog: false,
      body: "",
      content: {},
      attachments: [],
      fileName: "Add Attachment",
      disable: false,
      showClog: false, // 查看联系记录
      clogContent: {}, // 联系记录内容
      sendEmailData: {
        tos: ["1"],
        ccs: "",
        subject: "",
        desc: "",
      },
      total: 1, // 总页数
      currentPage: 1, // 当前页数
      limit: 5, // 每页显示数量
      data: [],
      current: 1,
      loading: false,
    };
  },
  computed: {
    datas() {
      var arr = [];
      this.data.forEach((item) => {
        let obj = { show: false };
        item = Object.assign(obj, item);
        arr.push(item);
      });
      return arr;
    },
    offset() {
      return (this.current - 1) * 5;
    },
    query() {
      return {
        offset: this.offset,
        limit: 5,
      };
    },
    title() {
      if (this.content) {
        return "Subject:" + " " + "<<" + this.content.subject + ">>";
      }
    },
  },
  filters: {
    intercept(val) {
      let arr = "";
      arr = val.slice(0, 500);
      return arr;
    },
  },
  props: ["contactID", "contact"],
  methods: {
    toggle() {
      let items = document.querySelector(".item");
      if (items) {
        if (items.offsetHeight < 200) {
          this.showText = false;
        } else {
          this.showText = true;
        }
      }
    },
    openShow(index) {
      let a = this.datas[index];
      a.show = true;
      this.$set(this.datas, index, a);
    },
    closeShow(index) {
      let a = this.datas[index];
      a.show = false;
      this.$set(this.datas, index, a);
    },
    pagesChanged(number) {
      this.current = number;
      this.getCommunicationLogs();
    },
    select() {
      if (!this.sendEmailData.ccs.trim()) return true;
      let regEmail = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
      return this.sendEmailData.ccs.trim().split(";").every((item, index) => {
          if (item.trim() !== "" && !regEmail.test(item.trim())) {
            this.$message.error( "Please fill in the correct email" );
            return false;
          }
          return true;
        });
    },
    start() {
      if (this.sendEmailData.tos.length === 0) {
        this.$message("Please select at least one contact email");
        return;
      }
      if (!this.select()) return;
      this.$refs.sendEmailForm.validate((valid) => {
        if (valid) {
          let tos = [];
          this.sendEmailData.tos.forEach((element) => {
            switch (element) {
              case "1":
                tos.push(this.contact.email1);
                break;
              case "2":
                tos.push(this.contact.email2);
                break;
              case "3":
                tos.push(this.contact.sec_email1);
                break;
              case "4":
                tos.push(this.contact.sec_email2);
                break;
            }
          });
          this.disable = true;
          if (this.sendEmailData.desc) {
            let ccsArray = [];
            if (this.sendEmailData.ccs.trim()) {
              ccsArray = this.sendEmailData.ccs.trim().split(";").filter(function (item, index) {
                  return item.trim();
              });
            }

            if(this.agent_email) {
              ccsArray.push(this.agent_email);
            }

            let ccs = this.agent_email?this.agent_email+";"+this.sendEmailData.ccs:this.sendEmailData.ccs;

            apiEmailToContact({
              "to_emails": tos,
              "cc_emails": ccsArray,
              "email_subject": this.sendEmailData.subject,
              "email_body": this.sendEmailData.desc,
              "attachments": this.attachments
            }).then((res) => {
              this.$message.success("Successfully sent Email to the contact!");
              this.sendEmailData.tos = ["1"];
                this.attachments = [];
                this.fileName = "Add Attachment";
                this.sendEmailData.ccs = this.sendEmailData.subject = this.sendEmailData.desc = "";
            }).catch((err) => {
              this.$message.error( err.response.data && err.response.data.error ? err.response.data.error.message : "Email send fail, please try again later.");
            }).finally((err) => {
                this.disable = false;
              });
          }else{
            this.$message.warning("Content cannot be empty")
          }
        }
      });
    },
    fileChange(event) {
      // 文件读取
      const reader = new FileReader();
      reader.readAsDataURL(event.file);

      reader.onload = () => {
        this.attachments.push({
          fileName: event.file.name,
          fileType: event.file.type,
          data: reader.result.split(",")[1],
        });
        this.fileName = event.file.name;
      };

      reader.onerror = (error) => {
        this.$message(error);
      };
    },
    // 查看邮件
    readEmailInfo(content) {
      this.showClog = true;
      this.clogContent = content;
    },
    // 回复邮件
    sumbit() {
      if (this.body) {
        let contect;
        if (this.content.body === null) {
          this.content.parts.forEach((item) => {
            if (item.mime_type === "text/html") {
              contect = item.body;
            } else {
              if (!contect) {
                contect = item.body;
              }
            }
          });
        } else {
          contect = this.content.body;
        }
        // let body = `${this.body}\n\n\nDate： ${dayjs(
        //   this.content.created_at
        // ).format("MM/DD/YYYY dddd HH:mm:ss")}\nTo： ${
        //   this.content.tos
        // }\nSubject： ${this.content.subject}
        // \n${contect}`;
        this.loadingreply = true;

        apiReplyToCommunicationLog({
          "communication_log_uuid": this.content.communication_log_uuid,
          "email_body": this.body,
          "reply_all": true
        }).then((res) => {
          this.showLog = false;
          this.body = "";
          this.$message.success("Email sent successfully");
        }).catch((err) => {
          this.$message.error(
            "Email send fail, please try again later",err.response
          );
        }).finally((err) => {
          this.loadingreply = false;
          this.disable = false;
        });
        // replyEmail(
        //   this.content.subject,
        //   body,
        //   this.content.tos,
        //   this.sendAllTos?this.agent_email+";"+this.content.ccs:this.agent_email,
        //   this.attachments,
        //   this.content.references,
        //   this.content.message_id,
        //   this.content.in_reply_to
        // )
        //   .then(
        //     (res) => {
        //       this.showLog = false;
        //       this.body = "";
        //       this.$message.success("Email send successfully");
        //       // 发送成功，邮件内容提交到服务器
        //     },
        //     (err) => {
        //       this.$message.error(
        //         "Email send fail, please try again later",err.response
        //       );
        //     }
        //   )
        //   .finally((err) => {
        //     this.loadingreply = false;
        //     this.disable = false;
        //   });
      }
    },
    reply(content, replyToAll) {
      this.showLog = true;
      this.sendAllTos = replyToAll;
      this.content = content;
    },
    // 获取 Communication Log
    getCommunicationLogs() {
      if (this.contactID === undefined) return;
      this.loading = true;
      apiGetCommunicationLogs(this.contactID, this.query)
        .then((res) => {
          this.total = res.count;
          this.data = res.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  directives: {
    focus: {
      // 注册一个局部的自定义指令 v-focus
      inserted: function (el, binding) {
        if (binding.value) {
          el.querySelector("input").focus(); // 获取焦点
        }
      },
    },
  },
  created() {
    this.agent_email = sessionStorage.getItem("gmail_email");
    this.getCommunicationLogs();
  },
};
</script>

<style lang="scss" scoped>
@import "../../../css/button.scss";
@import "../../../css/table.scss";
@import "../../../css/dialog.scss";
.communit {
  font-family: "Roboto-Regular", sans-serif;
  .addtenant {
    background: #ffffff 0% 0% no-repeat padding-box;
    // border-radius: 10px;
    // margin-top: 20px;
    font-size: 16px;
    padding: 0 20px 40px 0;
    .addtenant_top {
      text-align: left;
      color: #333333;
      font-family: "Roboto-Bold", sans-serif;
      padding: 20px 20px 0 20px;
      font-size: 20px;
    }
    .communication-top {
      padding-bottom: 20px;
    }
  }
  /deep/ .el-dialog{
    width: 600px;
    @media (max-width:992px) {
      width: 90%;
    }
  }
}
/* 查看邮箱 */
.view {
  text-align: left;
  .view_tip {
    font-family: "Roboto-Regular", sans-serif;
    font-size: 20px;
    margin-bottom: 10px;
  }
  .view_content {
    border: 1px solid #e5e5e5;
    min-height: 40px;
    border-radius: 4px;
    padding-left: 20px;
    line-height: 40px;
  }
  .view_body {
    min-height: 60px;
  }
}

.dialog-footer .delete {
  background-color: #678993;
  color: #fff;
  &:hover,
  &:focus {
    background-color: #57737b;
  }
}

.addtenant .btn {
  text-align: right;
}
// 回复邮件
.reply-content {
  text-align: left;
  p {
    margin-bottom: 7px;
    font-family: "Roboto-Regular", sans-serif;
  }
}
.reply-btn {
  margin-top: 20px;
}

/* 发送email */
.send_contant {
  padding: 0 40px;
  .upload_file {
    text-align: right;
    .upload-img {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin: 0;
      color: #678993;
      img {
        margin-left: 20px;
      }
    }
  }
  /deep/ .el-form-item__label::before {
    display: none;
  }
  /deep/ .el-form-item__content {
    text-align: left;
  }
  /deep/ .el-checkbox-button {
    margin: 0px 20px 20px 0;
    border-radius: 4px;
    .el-checkbox-button__inner {
      border: none;
      background: #f2f2f2;
      border-radius: 4px;
      width: 140px;
      width: 250px;
      &:hover {
        color: #333;
      }
    }
  }

  /deep/ .el-checkbox-button.is-checked {
    .el-checkbox-button__inner {
      background-color: #678993;
      box-shadow: -0.017857rem 0 0 0 #678993;
      &:hover {
        color: #fff;
      }
    }
  }
}
.tip {
  font-size: 16px;
}
/* Communication Log */
.communication {
  padding: 0 20px 20px 20px;
  font-size: 16px;
  .email-subiect {
    font-family: "Roboto-Bold", sans-serif;
    font-size: 16px;
    margin-bottom: 5px;
  }
  .email-body {
    margin-top: 0px;
    white-space: pre-wrap;
    font-family: "Roboto-Regular", sans-serif;
  }
  .fr {
    text-align: right;
    font-size: 10px;
    color: #678993;
    margin: 0;
    font-family: "Roboto-Bold", sans-serif;
  }
  .reply {
    color: #678993;
    font-size: 16px;
  }
  .read {
    color: #00b975;
    font-size: 16px;
  }

  // /deep/ .el-table {
  //   margin: 0px 40px 40px 40px;
  //   width: calc(100% - 80px);
  //   .become_angry {
  //     background-color: #e1e7e9;
  //   }
  //   .el-table__body tr:hover > td,
  //   .el-table__body tr.current-row > td {
  //     cursor: pointer;
  //   }
  //   th {
  //     background: #eff3f4;
  //     border: none;
  //   }
  //   th:first-child,
  //   th:last-child {
  //     border-radius: 4px 0px 0px 4px;
  //   }
  //   .el-table__row:last-child td,
  //   .el-table--border::after,
  //   .el-table--group::after,
  //   &::before {
  //     background: none;
  //     border: none;
  //   }
  //   .cell {
  //     text-align: center;
  //     font-size: 16px;
  //     font-family: "Lucida Sans Regular";
  //   }
  // }
}

.lb_page {
  padding-right: 40px;
  text-align: right;
  i:hover {
    cursor: pointer;
  }
}
@media only screen and (max-width: 767px) {
  .send_contant {
  padding: 0 0px;
}
}

</style>
