<template>
  <div class="main-email-detail" v-if="data">
    <div class="subject-email">
      <div class="main-icons">
        <div class="left-icons" @click="$emit('go-back')">
          <div class="crossicons">
            <img src="../../../assets/img/Dismiss.svg" alt="" />
          </div>
          <div class="closeicons">Close</div>
        </div>
      </div>
      <div class="mail-sequence">
        <b class="main-subject">{{ data.subject }}</b>
        <div>
          <img class="inbox-down" src="../../../assets/img/mailinbox.svg" alt="" />
          <img class="inbox-down" src="../../../assets/img/arrow-down.svg" alt="" />
        </div>
      </div>
    </div>

    <div class="main-sender">
      <div class="mail-header">
        <div class="logo-email">
          <div>
            <div class="mail-logo">S</div>
          </div>
          <div class="sender-align">
            <span>Sender &lt;{{ data.sender }}&gt;</span>
            <p>To: You</p>
          </div>
        </div>
        <!-- second arrows  -->
        <div>
          <p class="date-and-time">{{ formatDate(data.created_at) }}</p>
        </div>
      </div>

      <div v-if="data.body != null" class="mail-body">
        <p v-html="data.body"></p>
      </div>

      <div v-else class="mail-body">
        <p v-html="data.parts[data.parts.length - 1].body"></p>
      </div>
      
      <div class="mail-footer">
        <button class="forward-btn" @click="reply()">
          <div>
            <img
              class="img-arrows"
              src="../../../assets/img/ArrowReply.svg"
              alt=""
            />
          </div>
          <div class="top-down">Reply</div>
        </button>
        <button class="forward-btn">
          <div>
            <img
              class="img-arrows"
              src="../../../assets/img/ArrowForward.svg"
              alt=""
            />
          </div>
          <div class="top-down">Forward</div>
        </button>
      </div>
    </div>

    <el-dialog
      :visible.sync="showLog"
      :title="`Subject: << ${data.subject} >>`"
      :class="`replay-modal`"
    >
      <div class="reply-content">
        <p>
          To:
          <span
            v-for="(item, index) in data.tos"
            :key="index"
            style="display: inline-block; margin-right: 10px"
          >
            &lt;&lt;{{ item }}&gt;&gt;</span
          >
        </p>
        <el-input type="textarea" resize="none" :rows="4" v-model="body" />
      </div>
      <el-button
        class="primary-button reply-btn"
        :loading="loadingreply"
        @click="submit"
        >Send</el-button
      >
    </el-dialog>
  </div>
</template>

<script>
import { apiReplyToCommunicationLog } from "../../../API/api";
import dayjs from "dayjs";
export default {
  name: "EmailDetail",
  props: ["data"],
  data() {
    return {
      sendAllTos: false,
      showLog: false,
      body: "",
      showClog: false,
      loadingreply:false,
    };
  },
  methods: {
    reply() {
      this.showLog = true;
    },
    formatDate(date) {
      return dayjs(date).format("dd DD/MM/YYYY hh:mm a");
    },
    submit() {
      if (this.body) {
        let contect;
        if (this.data.body === null) {
          this.data.parts.forEach((item) => {
            if (item.mime_type === "text/html") {
              contect = item.body;
            } else {
              if (!contect) {
                contect = item.body;
              }
            }
          });
        } else {
          contect = this.data.body;
        this.loadingreply = true;

        apiReplyToCommunicationLog({
          "communication_log_uuid": this.data.communication_log_uuid,
          "email_body": this.body,
          "reply_all": true
        }).then((res) => {
          this.showLog = false;
          this.body = "";
          this.$message.success("Email sent successfully");
        }).catch((err) => {
          this.$message.error(
            "Email send fail, please try again later",err.response
          );
        }).finally((err) => {
          this.loadingreply = false;
        });
      }
      }
    },
  },
};
</script>

<style>
.add-email {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  border-radius: 4px;
  background: #8babb6;
  border: none;
  color: #fff;
}
.subject-email {
  margin: 25px 0px 35px 0px;
  border-radius: 4px;
  border: 1px solid #f0f0f0;
  background: #fff;
  padding: 10px;
}
.main-icons {
  display: flex;
  justify-content: space-between;
}
.left-icons {
  display: flex;
  gap: 5px;
  cursor: pointer;
}
.right-icons {
  display: flex;
  gap: 5px;
}
.mail-sequence {
  display: flex;
  gap: 10px;
}
.main-subject {
  color: #092a35;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.closeicons {
  color: #808080;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.crossicons {
  text-align: center;
  display: flex;
}
.logo-email {
  display: flex;
  gap: 10px;
}
.main-sender {
  border: 1px solid #f0f0f0;
  padding: 10px 10px 10px 10px;
}
.mail-header {
  display: flex;
  justify-content: space-between;
}
.mail-logo {
  width: 42px;
  height: 42px;
  padding: 4px 4px 4px 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 42px;
  background: #2f681f;
  display: flex;
  color: #fff;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
}
.sender-align {
  text-align: left;
}
.img-arrows {
  width: 20px;
  height: 20px;
  padding-right: 10px;
}
.mail-body {
  text-align: left;
  padding: 16px 16px 36px 16px;
  background: #fafafa;
  margin-left: 60px;
}
.mail-body p {
  color: #43555a;
  font-family: "Manrope";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.end-btn {
  display: flex;
  padding: 6px 22px;
}
.mail-footer {
  display: flex;
  padding: 10px 60px;
  gap: 10px;
}
.forward-btn {
  display: flex;
  padding: 10px 20px;
  border-radius: 4px;
  background: #e5f1f4;
  border: 1px solid #e5f1f4;
  cursor: pointer;
}
.top-down {
  padding-top: 4px;
}
.replay-modal .el-dialog__header {
  text-align: left;
  background-color: #fff;
  position: relative;
}

.replay-modal .el-dialog__title {
  font-size: 22px;
  color: #38425b;
}

.replay-modal .el-dialog__header::after {
  border-bottom: 1px solid #e1e9ef;
  width: calc(100% - 2px);
  bottom: 0;
  content: " ";
  left: 0;
  position: absolute;
}

.replay-modal .el-dialog__body {
  padding-top: 0;
}

.reply-btn {
  margin-top: 20px;
}

.reply-content {
  text-align: left;
}

.reply-content p {
  margin-bottom: 7px;
}

.replay-modal .el-dialog {
  width: 600px;
}
.inbox-down {
  cursor: pointer;
}
@media (max-width: 992px) {
  .replay-modal .el-dialog {
    width: 90%;
  }
}
@media only screen and (max-width: 600px) {
  .main-email-detail {
    margin: 0px;
    background: white;
  }
  .mail-body {
    margin-left: 40px;
  }
  .mail-footer {
    padding: 20px 0px;
    margin-left: 40px;
  }
  .end-btn {
    padding: 6px 24px;
  }
  .forward-btn {
    padding: 10px 24px;
  }
  .mail-logo {
    width: 25px;
    height: 25px;
  }
  .date-and-time {
    text-align: right;
  }
  .mail-header {
    display: block;
  }
  .main-row {
    background: white;
    padding: 12px 0px 0px 15px;
  }
  .left-icons {
    padding-left: 4px;
  }
  .main-subject {
    padding-left: 6px;
  }
  .subject-email {
    margin: 14px 0px 10px 0px;
    border: none;
  }
  .maintableclass {
    background: white;
  }
}

</style>
