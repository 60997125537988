<template>
  <div class="pauline-bass">
    <div class="pauline-title row">
      <div>
         <span>{{ data.first_name }} {{ data.last_name }}</span>
         <span style="font-size:13px;margin-left:30px;">Create Date: {{data.created_at?data.created_at:null | dateFormat}}</span>
      </div>
      <i class="el-icon-edit-outline" @click="edit"></i>
    </div>
    <el-divider></el-divider>
    <div class="contact-infor">
      <div class="contact-owner">
        <div class="row owner-row">
          <div class="item-margin-top">
            <p>Contact Owner</p>
            <p class="owner-item">{{ data.owner_name }}</p>
          </div>
          <div class="item-margin-top">
            <p>Types</p>
            <p class="owner-item">
              <span class="type-item" v-for="(item, index) in data.types" :key="index">{{ item | capitalize }}</span>
            </p>
          </div>
          <el-checkbox  class="item-margin-top" v-model="checked" disabled>Email Opt Out</el-checkbox>
        </div>
      </div>
      <div class="contact-owner">
        <div class="row row-basic">
          <div class="basic-box">
            <p>Name</p>
            <p class="owner-item">{{ data.first_name }} {{ data.last_name }}</p>
          </div>
          <div class="basic-box">
            <p>Email Address</p>
            <p class="owner-item">{{ data.email1 }}</p>
          </div>
          <div class="basic-box">
            <p>Email Address 2</p>
            <p class="owner-item">{{ data.email2 }}</p>
          </div>
          <div class="basic-box">
            <p>Cell Phone</p>
            <p class="owner-item">{{ data.cell_phone }}</p>
          </div>
          <div class="basic-box">
            <p>Home Phone</p>
            <p class="owner-item">{{ data.home_phone }}</p>
          </div>
          <div class="basic-box">
            <p>Work Phone</p>
            <p class="owner-item">{{ data.work_phone }}</p>
          </div>
          <div class="basic-box">
            <p>Street Address</p>
            <p class="owner-item">{{ data.street1 }}</p>
          </div>
          <div class="basic-box">
            <p>Street Address 2</p>
            <p class="owner-item">{{ data.street2 }}</p>
          </div>
          <div class="basic-box">
            <p>City</p>
            <p class="owner-item">{{ data.city }}</p>
          </div>
          <div class="basic-box">
            <p>State</p>
            <p class="owner-item">{{ data.state }}</p>
          </div>
          <div class="basic-box">
            <p>Country</p>
            <p class="owner-item">{{ data.country }}</p>
          </div>
          <div class="basic-box">
            <p>Zipcode</p>
            <p class="owner-item">{{ data.zip_code }}</p>
          </div>
        </div>
      </div>
      <div class="contact-owner" v-if="data.sec_first_name || data.sec_last_name">
        <p class="secondary-title">Secondary Contact</p>
        <div class="row row-basic" style="margin-top: 10px">
          <div class="basic-box">
            <p>Name</p>
            <p class="owner-item">
              {{ data.sec_first_name }} {{ data.sec_last_name }}
            </p>
          </div>
          <div class="basic-box">
            <p>Email Address</p>
            <p class="owner-item">{{ data.sec_email1 }}</p>
          </div>
          <div class="basic-box">
            <p>Email Address 2</p>
            <p class="owner-item">{{ data.sec_email2 }}</p>
          </div>
          <div class="basic-box">
            <p>Cell Phone</p>
            <p class="owner-item">{{ data.sec_cell_phone }}</p>
          </div>
          <div class="basic-box">
            <p>Home Phone</p>
            <p class="owner-item">{{ data.sec_home_phone }}</p>
          </div>
          <div class="basic-box">
            <p>Work Phone</p>
            <p class="owner-item">{{ data.sec_work_phone }}</p>
          </div>
          <div class="basic-box">
            <p>Street Address</p>
            <p class="owner-item">{{ data.sec_street1 }}</p>
          </div>
          <div class="basic-box">
            <p>Street Address 2</p>
            <p class="owner-item">{{ data.sec_street2 }}</p>
          </div>
          <div class="basic-box">
            <p>City</p>
            <p class="owner-item">{{ data.sec_city }}</p>
          </div>
          <div class="basic-box">
            <p>State</p>
            <p class="owner-item">{{ data.sec_state }}</p>
          </div>
          <div class="basic-box">
            <p>Country</p>
            <p class="owner-item">{{ data.sec_country }}</p>
          </div>
          <div class="basic-box">
            <p>Zipcode</p>
            <p class="owner-item">{{ data.sec_zip_code }}</p>
          </div>
        </div>
      </div>
      <br />
    </div>
  </div>
</template>
<script>
import Note from "../../common/Note";
import DelNote from "../../common/DelDialog";
import { Minixs } from "../../../js/mixins";
export default {
  mixins: [Minixs],
  components: { Note, DelNote },
  props: ["data"],
  data() {
    return {
      checked: true,
    };
  },
  methods: {
    edit() {
      this.$router.push({ name: "edit-contact", params: { id: this.$route.params.id }, });
    },
  },
  filters: {
    capitalize(value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../css/button.scss";
.pauline-bass {
  background-color: #fff;
  padding-top: 20px;
  .pauline-title {
    padding: 0 20px;
    justify-content: space-between;
    font-size: 20px;
    span {
      font-family: "Roboto-Bold", sans-serif;
    }
    i {
      color: #678993;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .contact-infor {
    padding: 0 20px;
    .contact-owner {
      position: relative;
      .secondary-title {
        text-align: left;
        margin-bottom: 0;
        font-family: "Roboto-Bold", sans-serif;
        font-size: 16px;
      }
      .owner-row {
        max-width: 700px;
        padding-bottom: 20px;
        .type-item {
          margin-right: 10px;
        }
      }
      /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
      /deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: #678993;
        border-color: #678993;
      }
      /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
        color: #606266;
      }
      &::after {
        content: "";
        border-bottom : 1px dashed #e1e9ef;
        bottom: 0;
        left: 0;
        width: 100%;
        position: absolute;
      }
    }
    .row {
      width: 100%;
      justify-content: space-between;
      font-size: 14px;
      text-align: left;
      .basic-box {
        text-align: left;
        margin-bottom: 20px;
        width: calc(100% / 4);
      }
      p {
        margin: 5px;
      }
      .owner-item {
        font-family: "Roboto-Bold", sans-serif;
      }
    }
    .row-basic {
      margin-top: 20px;
      justify-content: flex-start;
      -webkit-flex-wrap: wrap;
      flex-wrap: wrap;
    }
  }
  @media (max-width:992px) {
    .contact-infor{
      .contact-owner .owner-row {
        display: block;
        .item-margin-top{margin-top: 20px;}
      }
      .row-basic{
        display: block;padding-bottom: 1px;
        .basic-box{
          width: 100%;
          p{
            margin-bottom: 0px;
          }
        }
      }
    }
    
  }
}
</style>