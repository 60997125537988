<template>
  <div class="mian-head" v-loading="loading">
    <div>
      <div class="mail-container">
        <el-form ref="sendEmailForm" :model="sendEmailData">
          <div class="inner-data">
            <div class="title-input">To</div>
            <div class="form-input">
              <el-input
                type="text"
                placeholder="Contact Name <contact@domain.com>"
                v-model="sendEmailData.tos"
                readonly
                ></el-input>
            </div>
          </div>
          <div class="inner-data">
            <div class="title-input">CC</div>
            <div class="form-input">
              <el-input
                v-model="sendEmailData.ccs"
                placeholder="Separate multiple email with a semicolon"
                @blur="select()"
              ></el-input>
            </div>
          </div>

          <el-form-item
            prop="subject"
            :rules="[
              {
                required: true,
                message: 'This information is required',
                trigger: ['blur'],
              },
            ]"
          >
            <div class="inner-data">
              <div class="title-input">Subject</div>
              <div class="form-input">
                <el-input
                  v-model="sendEmailData.subject"
                  ref="subject"
                ></el-input>
              </div>
            </div>
          </el-form-item>
          <div class="note-block">
            <NoteEditor ref="desc" :dataPlaceholder="`Type your email here`"></NoteEditor>
          </div>
          <div class="btn-group">
            <el-button class="primary-button" @click="start">Send</el-button>
            <el-button class="cancel-button" @click="$emit('go-back')">Cancel</el-button>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import {
  apiGetCommunicationLogs,
  apiEmailToContact,
  apiReplyToCommunicationLog,
} from "../../../API/api";
import NoteEditor from "./ContactNoteEditor.vue";
export default {
  name: "AddEmail",
  components: {
    NoteEditor,
  },
  props:['contact'],
  data() {
    return {
      agent_email:"",
      loading:false,
      sendEmailData: {
        tos: "",
        ccs: "",
        subject: "",
        desc: "",
      },
    };
  },
  mounted(){
    this.sendEmailData.tos = this.contact.email1;
  },
  methods: {
    select() {
        if (!this.sendEmailData.ccs.trim()) return true;
      let regEmail =
        /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
      return this.sendEmailData.ccs
        .trim()
        .split(";")
        .every((item, index) => {
          if (item.trim() !== "" && !regEmail.test(item.trim())) {
            this.$message.error("Please fill in the correct email");
            return false;
          }
          return true;
        });
    },
    start() {
      // let regEmail = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
      // console.log(this.sendEmailData);
      // if (this.sendEmailData.tos.trim() !== "" && !regEmail.test(this.sendEmailData.tos.trim())) {
      //     this.$message.error("Please enter the correct contact email");
      //     return;
      // }
      if (!this.select()) return;
      this.$refs.sendEmailForm.validate((valid) => {
        if (valid) {
          let tos = [this.sendEmailData.tos];
          this.loading = true;
          this.sendEmailData.desc = this.$refs.desc.content;
          if (this.sendEmailData.desc) {
            let ccsArray = [];
            if (this.sendEmailData.ccs.trim()) {
              ccsArray = this.sendEmailData.ccs
                .trim()
                .split(";")
                .filter(function (item, index) {
                  return item.trim();
                });
            }

            if (this.agent_email) {
              ccsArray.push(this.agent_email);
            }

            let ccs = this.agent_email
              ? this.agent_email + ";" + this.sendEmailData.ccs
              : this.sendEmailData.ccs;

            apiEmailToContact({
              to_emails: tos,
              cc_emails: ccsArray,
              email_subject: this.sendEmailData.subject,
              email_body: this.sendEmailData.desc,
            })
              .then((res) => {
                this.$message.success(
                  "Successfully sent Email to the contact!"
                );
                this.sendEmailData.tos = 
                this.sendEmailData.ccs =
                  this.sendEmailData.subject =
                  this.sendEmailData.desc =
                    "";
                this.$emit('go-back');
              })
              .catch((err) => {
                this.$message.error(
                  err.response.data && err.response.data.error
                    ? err.response.data.error.message
                    : "Email send fail, please try again later."
                );
              })
              .finally((err) => {
                this.loading = false;
              });
          } else {
            this.$message.warning("Content cannot be empty");
          }
        }
      });
    },
    sumbit() {
      if (this.body) {
        let contect;
        if (this.content.body === null) {
          this.content.parts.forEach((item) => {
            if (item.mime_type === "text/html") {
              contect = item.body;
            } else {
              if (!contect) {
                contect = item.body;
              }
            }
          });
        } else {
          contect = this.content.body;
        }
        this.loadingreply = true;

        apiReplyToCommunicationLog({
          communication_log_uuid: this.content.communication_log_uuid,
          email_body: this.body,
          reply_all: true,
        })
          .then((res) => {
            this.showLog = false;
            this.body = "";
            this.$message.success("Email sent successfully");
          })
          .catch((err) => {
            this.$message.error(
              "Email send fail, please try again later",
              err.response
            );
          })
          .finally((err) => {
            this.loadingreply = false;
            this.loading = false;
          });
      }
    },
    getCommunicationLogs() {
      if (this.contactID === undefined) return;
      this.loading = true;
      apiGetCommunicationLogs(this.contactID, this.query)
        .then((res) => {
          this.total = res.count;
          this.data = res.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.agent_email = sessionStorage.getItem("gmail_email");
  },
};
</script>

<style>
.mail-container {
  width: 55%;
}
.title-input {
  color: #43555a;
  font-family: "Manrope";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}
.form-input {
  width: 75%;
}
.form-input input {
  color: #43555a;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  border: none;
  width: 100%;
}
.inner-data {
  display: flex;
  gap: 10px;
  border-bottom: 1.5px solid #d8e2e4;
  opacity: 0.7;
  padding: 5px 0px;
  align-items: center;
}
.note-block {
  padding-top: 10px;
}
.mian-head {
  background: white;
  padding: 0px 10px 7px 0px;
}
@media only screen and (max-width: 800px) {
  .mail-container {
    width: 100%;
  }
  .mian-head {
    background: white;
    padding: 0px 20px 7px 0px;
  }
}
</style>
