<template>
  <span ref="mobileViewBox" class="rental-table-mobile">
    <div v-if="data.length <= 0 " class="no-found-class"> No Data Found </div>
    <div v-for="(item,index) in data" :key="`rental-data-${index}`" class="table-block">    
        <span v-for="(col,colIndex) in header" :key="`rental-header-${colIndex}`">
            <div class="table-row border-bottom" v-if="col.label == 'LEASE'">
                <div class="title">#{{item.lease_id}}</div>
            </div>
            <div class="table-row" v-if="col.label == 'AGENT'">
                <div class="title">{{col.label}}</div>
                <div>{{item.user_first_name}}</div>
            </div>
            <div class="table-row" v-if="col.label == 'CONTRACT DATE'">
                <div class="title">{{col.label}}</div>
                <div>{{item.contract_date}}</div>
            </div>
            <div class="table-row" v-if="col.label == 'TENANT'">
                <div class="title">{{col.label}}</div>
                <div>{{item.tenant_last_name}}</div>
            </div>
            <div class="table-row" v-if="col.label == 'OWNER'">
                <div class="title">{{col.label}}</div>
                <div>{{item.owner_last_name}}</div>
            </div>
            <div class="table-row" v-if="col.label == 'RENTAL ADDRESS'">
                <div class="title">{{col.label}}</div>
                <div>{{item.listing_address}}</div>
            </div>
            <div class="table-row" v-if="col.label == 'ARRIVAL'">
                <div class="title">{{col.label}}</div>
                <div>{{item.arrival_date}}</div>
            </div>
            <div class="table-row" v-if="col.label == 'DEPARTURE'">
                <div class="title">{{col.label}}</div>
                <div>{{item.departure_date}}</div>
            </div>
            <div :class="`table-row deposit-status ${item.status}`" v-if="col.label == ''">
                <div class="dot"></div>
                <div> {{item.status}}</div>
            </div>
            <div class="table-row border-bottom" v-if="col.label == 'PRICE'">
                <div class="title">{{col.label}}</div>
                <div>Max Price | $ {{ item.max_price ? item.max_price.toLocaleString() : '0'}}</div>
            </div>
            <div class="table-row" v-if="col.label == 'IMAGE'">
                <div class="title">{{col.label}}</div>
                <div><img v-lazy="item.url" width="100px" height="70px"/></div>
            </div>
            <div class="table-row" v-if="col.label == 'AREA'">
                <div class="title">{{col.label}}</div>
                <div>{{item.area_name}}</div>
            </div>
            <div class="table-row" v-if="col.label == 'BRS'">
                <div class="title">{{col.label}}</div>
                <div>{{item.bedroom_number}}</div>
            </div>
            <div class="table-row" v-if="col.label == 'ADDRESS'">
                <div class="title">{{col.label}}</div>
                <div>{{item.address}}</div>
            </div>
            <div class="table-row" v-if="col.label == 'BATHS'">
                <div class="title">{{col.label}}</div>
                <div>{{item.bathroom_number}}</div>
            </div>
            <div class="table-row" v-if="col.label == 'CAPACITY'">
                <div class="title">{{col.label}}</div>
                <div>{{item.capacity}}</div>
            </div>
            <div class="table-row" v-if="col.label == 'TURNOVER'">
                <div class="title">{{col.label}}</div>
                <div>{{item.turnover_day}}</div>
            </div>
            <div class="table-row" v-if="col.label == 'LAST UPDATE'">
                <div class="title">{{col.label}}</div>
                <div>{{item.calendar_updated_at}}</div>
            </div>

        </span>
    </div>
  </span>
</template>

<script>
export default {
    name:"RentalTableMobile",
    props:['data','header'],
};
</script>

<style>
.no-found-class{
    margin-top: 20px;
    font-size: 20px;
    background: #F7FAFB;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.rental-table-mobile .table-block{
    border-radius: 4px;
    background: #F7FAFB;
    padding: 15px;
    margin: 10px 0px;
}

.rental-table-mobile .table-row.border-bottom{
    border-bottom: 1px solid #D8E2E4;
    padding-bottom: 10px;
}

.rental-table-mobile .table-row{
    display: flex;
    justify-content: space-between;
    color: #43555A;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 15px;
    align-items: center;
}

.rental-table-mobile .table-row .title{
    font-weight: 700;
}

.deposit-status .dot{
    width: 7px;
    height: 7px;
    border-radius: 50%;
    margin-right: 5px;
}
.deposit-status{
    justify-content: end !important;
    background: none !important;
}

.deposit-status.Current {
  background:#E9F6FF;
  color: #219FFF;
}
.deposit-status.Past  {
  background: #FEECF1;
  color: #F53D6B;
}
.deposit-status.Paid  {
  background: #E9FBF6;
  color: #21D3A0;
}

.deposit-status .dot{
  background:#219FFF;
}
.deposit-status  .dot{
  background: #F53D6B;
}
.deposit-status  .dot{
  background:  #21D3A0;
}
</style>
