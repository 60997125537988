<template>
  <div>
    <el-col :lg="5" class="sidebar">
      <div class="inner-sidebar">
        <h1>New Contact</h1>

        <p>
          <img src="../../../assets/img/call.svg" class="head-content" alt="" />
          Phone Number
        </p>
        <p>
          <img src="../../../assets/img/sms.svg" class="head-content" alt="" />
          Email Address
        </p>
      </div>
      <div class="personalinfo" id="app">
        <p>
          <img
            style="padding-right: 11px"
            src="../../../assets/img/user.svg"
            alt=""
          />Personal Information
          <img src="../../../assets/img/edit-2.svg" alt="" @click="$emit('toggle-edit')"/>
          <img
            class="icons-arrow"
            src="../../../assets/img/arrow-up.svg"
            alt=""
            v-if="display == true"
            @click="toggleBar()"
          />
          <img
            class="icons-arrow"
            src="../../../assets/img/arrow-down.svg"
            alt=""
            v-if="display != true"
            @click="toggleBar()"
          />
        </p>
      </div>
      <div class="scroll-bar" v-if="display">
        <div class="input-field1">
          <label for="">First Name</label>

          <div class="name-field1">
            <img
              class="icons-control"
              src="../../../assets/img/user.svg"
              alt=""
            />
            <input type="text" placeholder="Enter First Name" @change="$emit.valueUpdated($event,'first_name')" />
          </div>
        </div>
        <div class="input-field1">
          <label for="">Last Name</label>

          <div class="name-field1">
            <img
              class="icons-control"
              src="../../../assets/img/user.svg"
              alt=""
            />
            <input type="text" placeholder="Enter Last Name" v-model="country" />
          </div>
        </div>
        <div class="input-field1">
          <label for="">Email Address</label>

          <div class="name-field1">
            <img
              class="icons-control"
              src="../../../assets/img/sms.svg"
              alt=""
            />
            <input type="text" placeholder="Enter Your Email" v-model="country" />
          </div>
        </div>
        <div class="input-field1">
          <label for="">Email Address-2</label>

          <div class="name-field1">
            <img
              class="icons-control"
              src="../../../assets/img/sms.svg"
              alt=""
            />
            <input type="text" placeholder="Enter Email Address-2" v-model="country" />
          </div>
        </div>

        <div class="input-field1">
          <label for="">Mobile Phone</label>

          <div class="name-field1">
            <img
              class="icons-control"
              src="../../../assets/img/call.svg"
              alt=""
            />
            <input type="text" placeholder="Enter Mobile Phone" v-model="country" />
          </div>
        </div>
        <div class="input-field1">
          <label for="">Home Phone</label>

          <div class="name-field1">
            <img
              class="icons-control"
              src="../../../assets/img/call.svg"
              alt=""
            />
            <input type="text" placeholder="Enter Home Phone" v-model="country" />
          </div>
        </div>
        <div class="input-field1">
          <label for="">Work Phone</label>

          <div class="name-field1">
            <img
              class="icons-control"
              src="../../../assets/img/call.svg"
              alt=""
            />
            <input type="text" placeholder="Enter Work Phone" v-model="country" />
          </div>
        </div>
        <div class="input-field1">
          <label for="">Address</label>

          <div class="name-field1">
            <img
              class="icons-control"
              src="../../../assets/img/location.svg"
              alt=""
            />
            <input type="text" placeholder="Enter Your Address" v-model="country" />
          </div>
        </div>
        <div class="input-field1">
          <label for="">Address-2</label>

          <div class="name-field1">
            <img
              class="icons-control"
              src="../../../assets/img/location.svg"
              alt=""
            />
            <input type="text" placeholder="Enter Your Address-2" v-model="country" />
          </div>
        </div>
        <div class="input-field1">
          <label for="">City</label>

          <div class="name-field1">
            <img
              class="icons-control"
              src="../../../assets/img/location.svg"
              alt=""
            />
            <input type="text" placeholder="Enter Your City" v-model="country" />
          </div>
        </div>
        <div class="input-field1">
          <label for="">State</label>

          <div class="name-field1">
            <img
              class="icons-control"
              src="../../../assets/img/location.svg"
              alt=""
            />
            <input type="text" placeholder="Enter Your State" v-model="country" />
          </div>
        </div>
        <div class="input-field1">
          <label for="">Zip / Postal Code</label>

          <div class="name-field1">
            <img
              class="icons-control"
              src="../../../assets/img/location.svg"
              alt=""
            />
            <input type="text" placeholder="Zip / Postal Code" v-model="country" />
          </div>
        </div>
        <div class="input-field1">
          <label for="">Birthday</label>

          <div class="name-field1">
            <img
              class="icons-control"
              src="../../../assets/img/calendar-2.svg"
              alt=""
            />
            <input type="date" placeholder="Enter Birthday" v-model="country" />
          </div>
        </div>
        <div class="input-field1">
          <label for="">Job Title</label>

          <div class="name-field1">
            <img
              class="icons-control"
              src="../../../assets/img/briefcase.svg"
              alt=""
            />
            <input type="text" placeholder="Enter Job Title" v-model="country" />
          </div>
        </div>
        <div class="input-field1">
          <label for="">Company</label>

          <div class="name-field1">
            <img
              class="icons-control"
              src="../../../assets/img/buliding.svg"
              alt=""
            />
            <input type="text" placeholder="Enter Your Company" v-model="country" />
          </div>
          <div class="btn">
            <el-button class="">Clear</el-button>
            <el-button class="primary-button">Save</el-button>
          </div>
        </div>
      </div>
    </el-col>
  </div>
</template>

<script>
export default {
  name: "ContactDetail",
  data() {
    return {
      display: true,
    };
  },
  methods: {
    toggleBar() {
      this.display = !this.display;
    },
  },
};
</script>

<style>
.sidebar {
  border-radius: 10px;
  background: #fff;
}
/* .generalinfo {
  width: 18rem;
} */
.sidebar h1 {
  color: var(--dark-dark-1, #092a35);
  font-family: Manrope;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  padding: 20px 0px 5px 0px;
}
.inner-sidebar {
  text-align: left;
  padding-left: 20px;
}
.el-select__tags input {
  border-radius: 8px;
  background: var(--primary-main-shade, #e5f1f4);
}
.personalinfo {
  padding: 2px 0px 3px 0px;
  text-align: left;
  display: flex;
  padding-left: 21px;
}
.personalinfo p {
  color: var(--dark-dark-1, #092a35);
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
.input-field1 label {
  float: left;
  padding: 0px 0px 0px 1.1rem;
  color: var(--dark-dark-2, #43555a);
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}
.name-field1 {
  border-bottom: 1px solid #d8e2e4;
    width: 74%;
    position: relative;
    display: inline-flex;
    margin-left: 26px;
    padding: 7px 0px 18px 3px;
}
.name-field1 input {
  border: none;
  /* border-bottom: 1.5px solid var(--dark-dark-5, #d8e2e4); */
  opacity: 0.699999988079071;
  width: 64%;
}
.btn {
  padding: 20px;
  text-align: right;
}
@media only screen and (min-width: 1200px) {
  .el-col-lg-5 {
    width: 7rem;
    margin-right: 0.5rem;
  }
  .el-col-lg-7 {
    width: 16rem;
  }
}
@media only screen and (max-width: 600px){
  .name-field1 {
    margin-left: 10px;
  }
}
.head-content {
  padding-right: 6px;
}
.scroll-bar {
  overflow-y: scroll;
  height: 13rem;
}
.scroll-bar::-webkit-scrollbar {
  width: 5px;
  /* height: 10px; */
  border: 1.5px solid #d8e2e4;
  opacity: 0.699999988079071;
}
.scroll-bar::-webkit-scrollbar-thumb {
  background: #d8e2e4;
  border-radius: 10px;
  height: 5px;
}
.icons-control {
  width: 14px;
  height: 14px;
}
.icons-arrow {
  padding-left: 84px;
  width: 12px;
  color: gray;
}
</style>
