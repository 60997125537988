<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <div class="addtenant properties">
      <div class="addtenant_top">
        <span>Owner's Properties</span>
        <el-button class="block-button" @click="$refs.dialog.show = true">Connect A Listing</el-button>
      </div>
      <el-divider></el-divider>
      <div class="table">
        <el-table :data="data">
          <el-table-column prop="address" min-width="200px"></el-table-column>
          <el-table-column min-width="150px">
            <template slot-scope="scope">
              <el-button class="properties_btn" @click="jump2Property('edit-property', scope.row)" type="text" size="small">Property Info</el-button>
            </template>
          </el-table-column>
          <el-table-column min-width="150px">
            <template slot-scope="scope">
              <el-button class="properties_btn"  @click="jump2Property('property-calendar', scope.row)" type="text" size="small">Calendar</el-button>
            </template>
          </el-table-column>
          <el-table-column min-width="120px">
            <template slot-scope="scope">
              <el-button class="properties_btn"  @click="jump2Property('property-photo', scope.row)" type="text" size="small">Photos</el-button>
            </template>
          </el-table-column>
          <el-table-column min-width="180px">
            <template slot-scope="scope">
              <el-button class="properties_btn" @click="jump2Property('property-service', scope.row)" type="text" size="small">Service Providers</el-button>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot-scope="scope" min-width="120px">
              <el-button class="properties_btn" @click="jump2Lease(scope.row)" type="text" size="small">Leases</el-button>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot-scope="scope" min-width="120px">
              <el-button class="properties_btn btn-icon" @click="del(scope.row)" type="text" icon="el-icon-delete" ></el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <Properties-Dialog ref="dialog" @select="select">
      <template slot="add-listing">
        <div class="add-property">
          <span class="addtenant-btn" @click="addProperty" >+ Create a New Listing</span>
        </div>
      </template>
    </Properties-Dialog>
    <Del-Dialog @del="delListing" ref="delInfor" :title="title"></Del-Dialog>
  </div>
</template>

<script>
import DelDialog from "../../common/DelDialog"
import { apiGetListings, apiUpdateListing } from "../../../API/api";
import PropertiesDialog from "../../common/PropertiesDialog";
export default {
  name: "HomeownerAddProperty",
  data() {
    return {
      fullscreenLoading: false,
      data: [], // Homeowner 拥有的房屋列表
      infor: {},
      title:"Are you sure to disconnet this listing?"
    };
  },
  components: { PropertiesDialog, DelDialog },
  methods: {
    // 新增房屋
    addProperty() {
      this.$router.push({ name: "create-property" });
      this.$refs.dialog.show = true;
    },
    // 获取房主的房屋列表
    getHomeownerPropertyList() {
      apiGetListings({
        homeowner: this.$route.params.id,
        ordering: "-homeowner_updated_at",
      }).then((res) => {
        this.data = res.results;
      });
    },
    // 修改房屋的拥有者
    modifyPropertyOwner(id) {
      this.fullscreenLoading = true;
      apiUpdateListing(id, { homeowner: this.$route.params.id })
        .then((res) => {
          this.$refs.dialog.show = false;
          this.$message.success("New listing added successfully");
          this.getHomeownerPropertyList();
        })
        .catch((err) => {
          this.$message.error(err.response.data.homeowner[0]);
        })
        .finally(() => {
          this.$refs.dialog.show = false;
          this.fullscreenLoading = false;
        });
    },
    //设置给租客指定房屋
    select(val) {
      if (val.owner_name) {
        this.$confirm(
          "The listing you selected already has owner. Are you sure to add it?",
          "Add a new listing",
          {
            confirmButtonText: "Confirm",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        ).then(() => {
          this.modifyPropertyOwner(val.listing_id);
        });
      } else {
        this.modifyPropertyOwner(val.listing_id);
      }
    },
    jump2Property(name, listing) {
      this.$router.push({ name: name, params: { id: listing.listing_id } });
    },
    jump2Lease(listing) {
      this.$store.commit("setLeaseParam", {
        listing: listing.listing_id,
        listingAddress: listing.address,
      });
      this.$router.push({ name: "create-lease" });
    },
    del(infor) {
      this.$refs.delInfor.show = true;
      this.infor = infor;
    },
    delListing() {
      apiUpdateListing(this.infor.listing_id, { homeowner: null })
        .then((res) => {
          this.getHomeownerPropertyList();
        })
        .catch((err) => {
          this.$message.error(err.response.data.homeowner[0]);
        })
        .finally(() => {
          this.this.$refs.delInfor.show  = false;
        });
    },
  },
  created() {
    if (this.$route.params.id === undefined) return;
    this.getHomeownerPropertyList();
  },
};
</script>

<style lang="scss" scoped>
@import "../../../css/button.scss";
@import "../../../css/table.scss";
.addtenant-btn {
  font-size: 16px;
  border: 1px solid #57737b;
  border-radius: 6px;
  color: #57737b;
  padding: 8px 20px;
  &:hover {
    cursor: pointer;
  }
}

.add-property {
  text-align: left;
  margin: 10px 0;
}
.addtenant {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  margin-top: 20px;
  font-size: 16px;
  .addtenant_top {
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px 0 20px;
    span {
      font-family: "Roboto-Bold", sans-serif;
      color: #38425b;
      font-size: 22px;
    }
  }
}
.table /deep/ .el-table {
  th {
    display: none;
  }
  td {
    padding: 10px 0 10px 0;
    &:first-of-type {
      padding-left: 20px;
    }
  }
}
.properties_btn {
  color: #38425b;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 14px;
  /deep/ .el-icon-delete {
    color: #57737b;
  }
}
.delete-contect {
  .delete-icon {
    justify-content: center;
    .icon-border {
      justify-content: center;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      background-color: rgba(222, 27, 75, 0.2);
      i {
        color: rgb(222, 27, 75);
        font-size: 38px;
      }
    }
  }
  .delete-text {
    font-size: 20px;
    color: #36425d;
    font-family: "Roboto-Bold", sans-serif;
    margin: 0 50px 20px 50px;
  }
  .del-btn {
    .el-button {
      width: 100px;
    }
  }
}
</style>
