<template>
  <div class="task-box">
    <div class="table">
      <el-table :data="list">
        <el-table-column label="TYPE" width="150">
          <template slot-scope="scope">
            <span class="task-type">{{ scope.row.type.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="DUE DATE" width="120">
          <template slot-scope="scope">
            {{ scope.row.due_date | dateFormat }}
          </template>
        </el-table-column>
        <el-table-column min-width='240' label="DESCRIPTION" prop="note"></el-table-column>
        <el-table-column width="50">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="light" content="Edit" placement="top-start">
              <el-button icon="el-icon-edit-outline" @click="editTask(scope.row.task_uuid, scope.row, scope.$index)" class="btn"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column width="50">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="light" content="Complete"  placement="top-start">
              <el-button @click="complateTask(scope.row.task_uuid)" class="btn">
                <img src="../../../assets/icon/ico-check.svg" alt="" />
              </el-button>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column width="50">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="light" content="Delete" placement="top-start">
              <el-button icon="el-icon-delete" @click="deleteTask(scope.row.task_uuid)" class="btn"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <Page :total="total" :pageSize="5" @number="pagesChanged"></Page>
    </div>
    <!-- 修改task -->
    <el-dialog :visible.sync="revisetask" :title="title">
      <div class="task_content">
        <el-form :model="data" ref="data">
          <el-form-item>
            <el-switch v-model="data.send_email" active-color="#678993" inactive-text="Email Reminder on Due Date"></el-switch>
          </el-form-item>
          <el-form-item prop="title" :rules="rule.requir">
            <p class="task-title">Title</p>
            <el-input v-model="data.title"></el-input>
          </el-form-item>
          <el-form-item prop="type" :rules="rule.requir">
            <p class="task-title">Type</p>
            <el-select v-model="data.type" placeholder="">
              <el-option v-for="item in taskType" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item
            ref="contactName"
            prop="contact_name"
            :rules="rule.requir"
          >
            <p class="task-title">Associate with Contact</p>
            <p style="margin: 0" @click="$refs.contactDialog.show = true">
              <el-input v-model="data.contact_name" readonly></el-input>
            </p>
          </el-form-item> -->

          <el-form-item prop="due_date" :rules="rule.requir">
            <p class="task-title">Due Date</p>
            <el-date-picker v-model="data.due_date" type="date" placeholder format=" MM/dd/yyyy" value-format="yyyy-MM-dd" :picker-options="expireTimeOption"></el-date-picker>
          </el-form-item>

          <el-form-item>
            <p class="task-title">Notes</p>
            <el-input type="textarea" resize="none" :autosize="{ minRows: 2 }" v-model="data.note"></el-input>
          </el-form-item>
        </el-form>
        <div class="task-btn">
          <el-button class="clear-btn" @click="clear()">Cancel</el-button>
          <el-button @click="createTask()" :loading="btnLoading">Save</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import Page from "../../common/Paging";
import { apiGetTasks, apiUpdateTask, apiCreatTask, apiDelTask,} from "../../../API/api";
export default {
  data() {
    return {
      expireTimeOption: {
        disabledDate(date) {
          var times = Date.now() - 24 * 60 * 60 * 1000;
          return date.getTime() < times;
        },
      },
      btnLoading: false,
      revisetask: false,
      data: { send_email: true },
      list: [],
      rule: {
        requir: [
          {
            required: true,
            message: "this information is required",
            trigger: "blur",
          },
        ],
      },
      //   task
      title: "Create new task",
      total: 1,
      current: 1,
      limit: 5,
      taskId: "",
      index: 0,
      loadingTask: false,
    };
  },
  components: { Page },
  computed: mapState({
    taskType: (state) => state.enum.taskType,
    offset() {
      return (this.current - 1) * this.limit;
    },
    query() {
      return {
        has_signed_lease: false,
        limit: this.limit,
        offset: this.offset,
        contact: this.$route.params.id,
      };
    },
  }),
  methods: {
    ...mapActions(["getTaskType"]),
    editTask(id, data, index) {
      if (id != 1) {
        this.data = {
          send_email: data.send_email,
          note: data.note,
          due_date: data.due_date,
          title: data.title,
          contact_name: data.contact_name,
          type: data.type.id,
        };
        this.taskId = data.task_uuid;
        this.index = index;
        this.title = "Edit Task";
      } else {
        this.data = { send_email: true };
        this.title = "Create new task";
      }

      this.revisetask = true;
    },
    clear() {
      this.data.title = this.data.type = this.data.note = "";
      this.data.send_email = true;
      this.data.due_date = null;
    },
    complateTask(id) {
      apiUpdateTask(id, { is_completed: true }).then((res) => {
        this.list.forEach((item, index) => {
          if (item.task_uuid === id) {
            this.list.splice(index, 1);
          }
        });
      });
    },
    deleteTask(id) {
      apiDelTask(id).then((res) => {
        this.list.forEach((item, index) => {
          if (item.task_uuid === id) {
            this.list.splice(index, 1);
          }
        });
      });
    },
    createTask() {
      this.$refs.data.validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          delete this.data.contact_name;
          if (this.taskId) {
            apiUpdateTask(this.taskId, this.data)
              .then((res) => {
                this.revisetask = false;
                this.$set(this.list, this.index, res);
              })
              .finally(() => {
                this.btnLoading = false;
              });
          } else {
            let obj = { contact: this.$route.params.id };
            this.data = Object.assign({}, this.data, obj);
            apiCreatTask(this.data)
              .then((res) => {
                this.revisetask = false;
                this.current = 1;
                this.getTaskList();
                this.clear();
              })
              .finally(() => {
                this.btnLoading = false;
              });
          }
        } else {
          return false;
        }
      });
    },
    // 获取task列表
    pagesChanged(number) {
      this.current = number;
      this.getTaskList();
    },
    getTaskList() {
      apiGetTasks(this.query).then((res) => {
        this.list = res.results;
        this.total = res.count;
      });
    },
  },
  created() {
    this.getTaskList();
    if (this.taskType.length === 0) {
      this.getTaskType();
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../../css/table.scss";
@import "../../../css/button.scss";
@import "../../../css/task.scss";
@import "../../../css/dialog.scss";
.task-box {
  padding-bottom: 100px;
  /deep/ .el-table {
    td {
      padding: 10px 0;
      &:first-of-type {
        padding-left: 10px;
      }
      .task-type {
        display: inline-block;
        background-color: #f4f9fc;
        border: 1px solid #e1e9ef;
        padding: 5px 10px 5px 10px;
        border-radius: 2px;
      }
    }
    th {
      &:first-of-type {
        padding-left: 10px;
      }
    }
  }

  .btn {
    border: none;
    background: none;
    padding: 0;
    /deep/ i {
      font-size: 20px;
      color: #678993;
    }
  }
  /deep/ .el-dialog{
    width: 400px;
    @media (max-width:992px) {
      width: 90%;
    }
  }
}
</style>