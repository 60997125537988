<template>
  <div class="note_box">
    <slot name="note_title"></slot>
    <div class="table">
      <el-table :data="datas" type="index">
        <el-table-column label="DATE" width="150px">
          <template slot-scope="scope">
            <p v-if="getDate(scope.row.date) === '1971'">Pre - 2021</p>
            <p v-else>{{ scope.row.date | dateFormat }}</p>
          </template>
        </el-table-column>
        <el-table-column width="200px" label="POSTED BY">
          <template slot-scope="scope">
            <p v-if="getDate(scope.row.date) === '1971'">Old System</p>
            <p v-else>{{ scope.row.posted_by }}</p>
          </template>
        </el-table-column>
        <!-- <el-table-column label="NOTE" prop="content" show-overflow-tooltip></el-table-column> -->
        <el-table-column label="NOTE" min-width="300">
          <template slot-scope="scope">
            <div class="note-text" v-if="scope.row.content.length <= 400">
              {{ scope.row.content }}
            </div>
            <div v-else-if="scope.row.content.length > 400">
              <div v-show="!scope.row.show">
                <p class="note-text">{{ scope.row.content | intercept }}...</p>
                <p class="fr" @click="openShow(scope.$index)">READ MORE</p>
              </div>
              <div v-show="scope.row.show">
                <p class="note-text">{{ scope.row.content | character }}</p>
                <p class="fr" @click="closeShow(scope.$index)">PACK UP</p>
              </div>
            </div>
          </template>
        </el-table-column>
        <slot name="edit_btn"></slot>
        <!-- <el-table-column width="50"> -->
        <slot name="table_btn"></slot>
        <!-- </el-table-column> -->
      </el-table>
    </div>
    <Page :total="total" :pageSize="5" @number="pagesChanged"></Page>
    <div class="create_note">
      <slot name="note_btn"></slot>
    </div>

    <el-dialog title="Create Note" append-to-body :visible.sync="show">
      <div class="sh_form">
        <el-form>
          <el-form-item label="Note">
            <el-input
              v-model="note"
              type="textarea"
              resize="none"
              :autosize="{ minRows: 5 }"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="btn">
          <el-button class="light-blue-button" @click="clear">Cancel</el-button>
          <el-button
            class="primary-button"
            @click="createNote"
            :loading="loading"
            >Save</el-button
          >
        </div>
      </div>
    </el-dialog>
    <el-dialog title="Edit Note" append-to-body :visible.sync="showEdit">
      <div class="sh_form">
        <el-form>
          <el-form-item label="Note">
            <el-input
              v-model="noteEdit"
              type="textarea"
              resize="none"
              :autosize="{ minRows: 5 }"
            />
          </el-form-item>
        </el-form>
        <div class="btn">
          <el-button class="light-blue-button" @click="clear">Cancel</el-button>
          <el-button
            class="primary-button"
            @click="updateEditNote"
            :loading="loadingNoteEdit"
            >Save</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Page from "./Paging";
import dayjs from "dayjs";
export default {
  props: ["noteData", "total"],
  components: { Page },
  data() {
    return {
      note: "",
      show: false,
      showText: false,
      showTextArr: [],
      dhow: [{ showText: false }],
      loading: false,
      noteEdit: "",
      showEdit: false,
      loadingNoteEdit: false,
    };
  },
  filters: {
    intercept(val) {
      let arr = "";
      arr = val.slice(0, 400);
      return arr;
    },
  },
  computed: {
    datas() {
      var arr = [];
      if (this.noteData&&this.noteData.length != 0) {
        this.noteData.forEach((item) => {
          let obj = { show: false };
          item = Object.assign(obj, item);
          arr.push(item);
        });
      }
      return arr;
    },
  },
  methods: {
    getDate(date) {
      return dayjs(date).format("YYYY");
    },
    updateEditNote() {
      if (this.noteEdit) {
        this.$emit("updateEditNote", this.noteEdit);
      }
    },
    pagesChanged(number) {
      this.$emit("pagesChanged", number);
    },
    openShow(index) {
      let a = this.datas[index];
      a.show = true;
      this.$set(this.datas, index, a);
    },
    closeShow(index) {
      let a = this.datas[index];
      a.show = false;
      this.$set(this.datas, index, a);
    },
    delNote(item) {
      this.$emit("delNote", item);
    },
    clear() {
      this.show = false;
      this.showEdit = false;
      this.note = "";
    },
    createNote() {
      if (this.note) {
        this.$emit("createNote", this.note);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../css/table.scss";
@import "../../css/button.scss";
@import "../../css/dialog.scss";
.note_box {
  background-color: #fff;
  /deep/ .el-table td {
    padding: 10px 0;
  }
  .note-text {
    margin-bottom: 0;
    white-space: pre-wrap;
  }
  .fr {
    text-align: right;
    font-size: 10px;
    color: #678993;
    margin: 0;
    font-family: "Roboto-Bold", sans-serif;
  }
  .sh_form {
    /deep/ .el-form-item__label {
      font-family: "Roboto-Regular", sans-serif;
    }
    /deep/ .el-textarea__inner {
      font-family: "Roboto-Regular", sans-serif;
    }
  }
  .btn {
    text-align: right;
    .el-button {
      width: 100px;
    }
  }
}
/deep/.el-dialog {
  width: 500px;
  @media (max-width: 992px) {
    width: 90%;
  }
}
</style>