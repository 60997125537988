<template>
  <div class="main-row" v-loading="loading">
    <div v-if="current_component != 'AddEmail'" class="new-email">
      <button
        class="add-email"
        type="button"
        @click="gotoComponent('AddEmail')"
      >
        <div>
          <img
            style="color: white"
            src="../../../assets/img/addwhite.svg"
            alt=""
          />
        </div>
        New email
      </button>
    </div>

    <table class="maintableclass" v-if="current_component == 'EmailListing'">
      <thead>
        <th class="thead-from">From</th>
        <th class="subject-line">Subject</th>
        <th class="received-down">
          <div>
            Received
          </div>
          <div>
            <img
              :class="`icon-arrow ${this.email_ordering}`"
              src="../../../assets/img/arrow-down.svg"
              @click="sort()"
            />
          </div>
        </th>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in data"
          :key="index"
          @click="emailDetail(item)"
        >
          <td>
            <div class="logo-name">
              <div class="profile-logo">
                {{ item.sender.toUpperCase().charAt(0) }}
              </div>
              <div class="from-name">{{ item.sender }}</div>
            </div>
          </td>
          <td>
            <div>
              <b>{{ item.subject }}</b> <p v-if="item.body != null" v-html="item.body"></p> <p v-else v-html="item.parts[item.parts.length - 1].body"></p>
            </div>
          </td>
          <td>
            <span class="time-block"> {{ formatDate(item.created_at) }} </span>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- mobile view display  -->
    <div class="mobile-view-mian" v-if="current_component == 'EmailListing'">
      <div v-for="(item, index) in data"
          :key="index"
          @click="emailDetail(item)">
        
      <div class="logo-date">
        <div class="mb-logo-name">
          <div class="mb-logo">{{ item.sender.toUpperCase().charAt(0) }}</div>
          <div class="mb-name">{{item.sender}}</div>
        </div>
        <div class="mb-date-time">
          {{ formatDate(item.created_at) }}
        </div>
      </div>
      <div class="subject-content">
        <div class="mb-subject">{{item.subject}}</div>
        <div class="mb-content">
          <p v-if="item.body != null" v-html="item.body"></p> <p v-else v-html="item.parts[item.parts.length - 1].body"></p>
        </div>
      </div>
      </div>

    </div>

    <EmailDetail
      v-if="current_component == 'EmailDetail'"
      :data="current_email"
      @go-back="gotoComponent('EmailListing')"
    ></EmailDetail>
    <AddEmail
      v-if="current_component == 'AddEmail'"
      :contact="contact"
      @go-back="gotoComponent('EmailListing')"
    ></AddEmail>

    <!-- mobile view display end  -->
  </div>
</template>

<script>
import dayjs from "dayjs";
import {
  apiGetCommunicationLogs
} from "../../../API/api";
import EmailDetail from "./EmailDetail.vue";
import AddEmail from "./AddEmail.vue";
export default {
  name: "ContactEmail",
  props: ["contactID", "contact"],
  components: {
    EmailDetail,
    AddEmail,
  },
  data() {
    return {
      current_component: "EmailListing",
      loading: false,
      data: [],
      current_email: null,
      email_ordering:"descending",
    };
  },
  computed:{
    query() {
      return {
        ordering:this.email_ordering == 'descending' ? "-created_at" : "created_at"
      };
    },
  },
  methods: {
    gotoComponent(component) {
      this.current_component = component;
    },
    getCommunicationLogs() {
      if (this.contactID === undefined) return;
      this.loading = true;
      apiGetCommunicationLogs(this.contactID,this.query)
        .then((res) => {
          this.data = res.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    formatDate(date) {
      return dayjs(date).format("MMM DD, YYYY hh:mm a");
    },
    emailDetail(item) {
      this.current_email = item;
      this.current_component = "EmailDetail";
    },
    sort(){
      this.email_ordering = this.email_ordering == 'ascending' ? 'descending' : 'ascending';
      this.getCommunicationLogs();
    }
  },
  created() {
    this.getCommunicationLogs();
  },
};
</script>

<style>
.maintableclass {
  width: 100%;
  overflow-y: scroll;
}
.maintableclass tr {
  cursor: pointer;
}
thead th {
  text-align: left;
  padding-left: 10px;
}
tbody td {
  border-bottom: 1px solid #f0f0f0;
  text-align: left;
  padding-left: 10px;
  color: #43555a;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
}

.main-row {
  padding: 25px 20px;
  background: #ffffff;
}
.add-email {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  border-radius: 4px;
  background: #8babb6;
  border: none;
  color: #fff;
  cursor: pointer;
}
table {
  padding: 40px 0px 0px 0px;
}
.from-name {
  padding: 10px;
  color: var(--dark-dark-1, #092a35);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}
.logo-name {
  padding: 1px 0px 0px 1px;
}
.unread.logo-name {
  border-left: 5px solid #316ab7;
}
.profile-logo {
  width: 28px;
  height: 28px;
  border-radius: 100px;
  background: #d02c89;
  color: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

/* new  */
.thead-from {
  color: #092a35;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  min-width: 120px;
  /* padding-left: 5%; */
}
.logo-name {
  display: flex;
  padding: 5px;
  align-items: center;
  flex-wrap:wrap;
}
.received-down {
  border-left: 1px solid #dcdfe6;
  color: #092a35;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 10px;
  min-width: 131px;
}

.received-down .icon-arrow{
  width:15px;
}
.received-down .icon-arrow.ascending{
  transform: rotate(180deg);
}
.subject-line {
  border-left: 1px solid #dcdfe6;
  color: #092a35;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  min-width: 50vh;
  text-overflow: ellipsis;
}
table th td {
  max-width: 400px !important;
}
.btn-group {
  text-align: left;
  margin: 10px 0px;
}
.cancel-button {
  border-radius: 4px;
  border: 1.5px solid #f53d6b !important;
  color: #f53d6b !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  background: none !important;
}
.time-block {
  padding-left: 10px;
}
@media only screen and (min-width: 1200px) {
  .el-col-lg-19 {
    width: 70.16667%;
    background: white;
  }
}
@media only screen and (min-width: 900px) {
  .mobile-view-mian {
    display: none;
  }

}
@media only screen and (max-width: 1200px) {
  .subject-line {
    min-width: auto;
  }
}
@media only screen and (max-width: 900px) {
  .maintableclass {
    display: none;
  }
  .mobile-view-mian {
    padding: 20px 0px;
    background: white;
  }
  .mb-logo-name {
    display: flex;
    gap: 20px;
  }
  .mb-logo {
    display: flex;
    width: 28px;
    height: 28px;
    padding: 4px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 28px;
    background: #d02c89;
    color: white;
    font-family: "Poppins";
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
  }
  .mb-name {
    color: #092a35;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    padding: 5px 0px 0px 0px;
  }
  .logo-date {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
  }
  .subject-content {
    display: flex;
    padding: 10px 0px 12px 8px;
    border-bottom: 1px solid #f0f0f0;
  }
  .mb-subject {
    color: #43555a;
    font-family: "Poppins";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    padding-right: 10px;
  }
  .mb-date-time {
    padding-top: 9px;
  }
  .mb-content {
    color: #6e7e83;
    text-overflow: ellipsis;
    font-family: "Poppins";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }
  .mb-content p {
    text-align: left;
  }
}
</style>
